import { Skeleton } from '@scalingworks/react-admin-ui';

import { type PriceTier } from '~/api';

const PriceTierTableHeader = () => (
	<thead>
		<tr>
			<th className="text-left font-medium">Range (KM)</th>
			<th className="text-right font-medium">Price</th>
		</tr>
	</thead>
);

const PriceTierTableImpl = function PriceTierTable(props: {
	tiers: Array<PriceTier>;
	className?: string;
}) {
	return (
		<table className={props.className}>
			<PriceTierTableHeader />
			<tbody>
				{props.tiers.map((tier, index) => (
					<tr key={index}>
						<td>{tier.to ? `${tier.from}-${tier.to}` : `${tier.from} and above`}</td>
						<td className="text-right">{tier.price}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export const PriceTierTable = Object.assign(PriceTierTableImpl, {
	Skeleton: (props: { className?: string }) => (
		<table className={props.className}>
			<PriceTierTableHeader />
			<tbody>
				<tr>
					<td>
						<Skeleton heightClass="h-[26px]" />
					</td>
					<td className="text-right">
						<Skeleton heightClass="h-[26px]" />
					</td>
				</tr>
				<tr>
					<td className="pr-2">
						<Skeleton width="medium" heightClass="h-[26px]" />
					</td>
					<td className="text-right">
						<Skeleton heightClass="h-[26px]" />
					</td>
				</tr>
			</tbody>
		</table>
	),
});
