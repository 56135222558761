import {
	Button,
	Card,
	DescList,
	IconButton,
	Skeleton,
	formatDate,
	isString,
} from '@scalingworks/react-admin-ui';
import { Content, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { sentenceCase } from 'change-case';
import { HiDotsVertical, HiLocationMarker, HiOutlineSparkles } from 'react-icons/hi';
import { IoNavigateOutline } from 'react-icons/io5';

import { type Customer, type Order, OrderStatus, type ServiceProvider, SortOrder } from '~/api';
import { OrderStatusTag } from '~/components/order-status-tag';
import { PaymentStatusTag } from '~/components/payment-status-tag';
import { PersonDetails } from '~/components/person-details';
import { notificationAudio } from '~/lib/notification-audio';

import { AssignDriverDialog } from './order-resource/assign-driver-dialog';
import { OrderActionsMenu } from './order-resource/order-actions-menu';
import { ShowMapButton } from './order-resource/show-map-button';
import { resourceNames } from './resource-names';

const { defineFields, defineShowPage } = createHelpers<Order>({
	resourceName: resourceNames.order,
});

const [formatedScheduledPickupTime] = defineFields([
	{
		operation: 'formattedScheduledPickupTime',
		variables: {
			timezone: {
				value: Intl.DateTimeFormat().resolvedOptions().timeZone,
				type: 'String',
			},
		},
		fields: [],
	},
]);

export const orderResource = createResource({
	name: resourceNames.order,
	label: 'Trips',
	icon: <IoNavigateOutline />,
	fields: defineFields([
		'id',
		'orderNumber',
		formatedScheduledPickupTime,
		'status',
		'statusDisplay',
		'createdAt',
		{
			serviceProvider: ['id', 'fullName', 'mobileNumber'],
			customer: ['id', 'fullName', 'mobileNumber'],
		},
	]),
	defaultValues: {},
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	allowCreate: false,
	allowEdit: false,
	allowSearch: true,
	timeSensitive: {
		pollingInterval: 5000,
		subscribe: (cb) => {
			const evtSource = new EventSource(`${import.meta.env.VITE_API_BASE_URL}/api/orders/sse`);
			const onMessage = () => cb('New order is created');
			evtSource.addEventListener('message', onMessage);

			return function unsubscribe() {
				evtSource.removeEventListener('message', onMessage);
			};
		},
		onToggleNotification: () => notificationAudio.requestPermission(),
		onNewNotification: () => notificationAudio.play(),
	},
	filterControls: {
		orderNumber: {
			type: 'text',
			config: {
				label: 'Order #',
			},
			operator: 'contains',
		},
		createdAt: {
			type: 'daterange',
			config: {
				label: 'Order date',
			},
		},
		'.customer.is.mobileNumber||email': {
			type: 'text',
			config: {
				label: 'Customer (mobile/email)',
			},
			operator: 'contains',
		},
		'.serviceProvider.is.mobileNumber||email': {
			type: 'text',
			config: {
				label: 'Driver (mobile/email)',
			},
			operator: 'contains',
		},
	},
	columns: ({ refetchData, LinkToDetails }) => [
		{
			id: 'id',
			accessorKey: 'id',
			header: 'Order #',
			cell: (data) => {
				const id = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={id} className="font-semibold">
						{data.row.original.orderNumber}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'createdAt',
			accessorKey: 'createdAt',
			header: 'Order date',
			cell: (data) => {
				const dateValue = data.cell.getValue<string>();

				return (
					dateValue &&
					formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd/MMM/yy hh:mm a' })
				);
			},
		},
		{
			id: 'formattedScheduledPickupTime',
			accessorKey: 'formattedScheduledPickupTime',
			header: 'Pick-up time',
		},
		{
			id: 'customer',
			accessorFn: (row) => row.customer,
			header: 'Customer',
			cell: (data) => {
				const customer = data.cell.getValue<Customer>();

				return customer ? (
					<LinkToDetails resourceName={resourceNames.customer} resourceId={customer.id}>
						{customer.fullName}
						<br />
						{customer.mobileNumber}
					</LinkToDetails>
				) : (
					'-'
				);
			},
		},
		{
			id: 'serviceProvider',
			accessorFn: (row) => row.serviceProvider,
			header: 'Driver',
			cell: (data) => {
				const driver = data.cell.getValue<ServiceProvider>();

				return driver ? (
					<LinkToDetails resourceName={resourceNames.driver} resourceId={driver.id}>
						{driver.fullName}
						<br />
						{driver.mobileNumber}
					</LinkToDetails>
				) : (
					'-'
				);
			},
		},
		{
			id: 'status',
			accessorKey: 'status',
			cell: (data) => {
				const status = data.cell.getValue<OrderStatus>();
				const statusText = data.row.original.statusDisplay;
				return <OrderStatusTag status={status} displayText={statusText} />;
			},
		},
		{
			id: 'actions',
			header: () => <div className="text-right">Actions</div>,
			accessorKey: 'id',
			cell: (data) => {
				const id = data.cell.getValue<string>();

				const orderStatus = data.row.original.status;

				const isFinal = isOrderFinal(orderStatus);

				return (
					<span className="flex items-center justify-end gap-2">
						{isFinal ? (
							<span className="text-gray-500 text-sm cursor-not-allowed px-1">Assign</span>
						) : (
							<AssignDriverDialog
								orderId={id}
								onSuccess={() => refetchData()}
								initialDriverId={data.row.original.serviceProvider?.id}
								button={
									<button type="button" className="text-primary-500 text-sm font-bold px-1">
										Assign
									</button>
								}
							/>
						)}
						<OrderActionsMenu
							orderId={id}
							onSuccess={refetchData}
							button={
								<IconButton>
									<HiDotsVertical aria-hidden />
									<span className="sr-only">More</span>
								</IconButton>
							}
							disabled={isFinal}
						/>
					</span>
				);
			},
		},
	],
	list: {
		tabs: {
			options: [
				OrderStatus.Allocating,
				OrderStatus.Ongoing,
				OrderStatus.Completed,
				OrderStatus.Failed,
				OrderStatus.Cancelled,
			].map((status) => ({
				label: sentenceCase(status),
				filterValue: {
					field: 'status',
					value: status,
					operator: 'eq',
				},
			})),
			allowMultiSelect: true,
		},
	},
	show: defineShowPage({
		title: (order) => `Trip ${order.orderNumber}`,
		fields: defineFields([
			'orderNumber',
			'status',
			'statusDisplay',
			'paymentStatus',
			'paymentMethod',
			formatedScheduledPickupTime,
			'totalDistance',
			'customerNotes',
			'couponCode',
			{
				wayPoints: [
					'address',
					'formattedAddress',
					{
						coordinates: ['latitude', 'longitude'],
					},
				],
				serviceProvider: [
					'id',
					'fullName',
					'profilePicture',
					'email',
					'mobileNumber',
					'rating',
					'ratingCount',
				],
				vehicleSnapshot: ['licensePlate', 'make', 'model'],
				customer: ['id', 'fullName', 'profilePicture', 'email', 'mobileNumber', 'ordersCount'],
				subTotalAmount: ['formattedAmount'],
				discountAmount: ['formattedAmount'],
				totalAmount: ['formattedAmount'],
			},
			{
				operation: 'events',
				variables: {
					orderBy: {
						value: [{ createdAt: SortOrder.Desc }],
						type: '[OrderEventOrderByWithRelationInput!]',
					},
				},
				fields: ['title', 'createdAt', 'payload', 'id'],
			} as any,
		]),
		component: function OrderShowPage({ queryResult, resourceId, title }) {
			const orderData = queryResult.data && queryResult.data.data;

			const isFinal = orderData && isOrderFinal(orderData.status);

			return (
				<Content
					title={
						orderData && (
							<div className="flex flex-col md:flex-row md:items-center gap-3">
								<h1 className="text-2xl">{title}</h1>
								<div className="flex gap-2">
									<OrderStatusTag status={orderData.status} displayText={orderData.statusDisplay} />
									<PaymentStatusTag status={orderData.paymentStatus} />
								</div>
							</div>
						)
					}
					isLoading={queryResult.isLoading}
					extra={
						!isFinal && (
							<OrderActionsMenu
								orderId={resourceId}
								onSuccess={() => queryResult.refetch()}
								button={
									<Button variant="solid" size="md">
										Action
									</Button>
								}
							/>
						)
					}
				>
					<div className="flex flex-col gap-3 xl:flex-row-reverse xl:items-start xl:gap-6">
						<section className="flex flex-col lg:flex-row xl:flex-col xl:w-96 gap-3">
							<Card className="flex-1">
								<Card.Header bordered>Driver</Card.Header>
								<Card.Body>
									{orderData ? (
										orderData.serviceProvider ? (
											<PersonDetails
												person={orderData.serviceProvider}
												resourceName={resourceNames.driver}
												subText={
													<span className="text-xs text-gray-600">
														{orderData.serviceProvider.ratingCount > 0
															? `${orderData.serviceProvider.rating} (${orderData.serviceProvider.ratingCount} reviews)`
															: 'No rating yet'}
													</span>
												}
											/>
										) : isFinal ? (
											<p className="text-center text-gray-500">None assigned</p>
										) : (
											<AssignDriverDialog
												orderId={resourceId}
												onSuccess={() => queryResult.refetch()}
												button={
													<Button variant="solid" className="w-full">
														Assign
													</Button>
												}
											/>
										)
									) : (
										<PersonDetails.Skeleton />
									)}
								</Card.Body>
							</Card>
							<Card className="flex-1">
								<Card.Header bordered>Customer</Card.Header>
								<Card.Body>
									{orderData ? (
										<PersonDetails
											person={orderData.customer}
											resourceName={resourceNames.customer}
											subText={
												<span className="text-xs text-gray-600">
													{orderData.customer.ordersCount} orders
												</span>
											}
										/>
									) : (
										<PersonDetails.Skeleton />
									)}
								</Card.Body>
							</Card>
						</section>
						<section className="flex flex-col xl:flex-1 gap-3">
							<Card>
								<Card.Header bordered>Details</Card.Header>
								<Card.Body>
									<DescList isLoading={queryResult.isLoading}>
										<DescList.Item
											label="Pick-up time"
											value={orderData && orderData.formattedScheduledPickupTime}
										/>
										<DescList.Item
											label="Locations"
											value={
												orderData &&
												orderData.wayPoints && (
													<div className="flex flex-col gap-3 @md/desc:flex-row-reverse @md/desc:justify-end">
														<ul className="flex flex-col gap-3">
															{orderData.wayPoints.map((point, index) => (
																<li className="flex items-center gap-2" key={index}>
																	{index ? (
																		<div className="relative">
																			<HiLocationMarker className="w-7 h-7 text-green-500" />
																			<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-3 text-center rounded-full text-xs bg-green-500 text-white font-medium">
																				{index}
																			</span>
																		</div>
																	) : (
																		<div className="p-1">
																			<span className="flex w-5 h-5 border-[6px] border-blue-500 rounded-full" />
																		</div>
																	)}
																	{point.formattedAddress}
																</li>
															))}
														</ul>
														<ShowMapButton points={orderData.wayPoints} />
													</div>
												)
											}
											span={2}
										/>
										<DescList.Item
											label="Total distance"
											value={orderData && `${orderData.totalDistance} KM`}
										/>
										<DescList.Item
											label="Vehicle"
											value={
												orderData &&
												orderData.vehicleSnapshot && (
													<div>
														<div>{orderData.vehicleSnapshot.licensePlate}</div>
														<div className="text-xs text-gray-600">
															{orderData.vehicleSnapshot.make} - {orderData.vehicleSnapshot.model}
														</div>
													</div>
												)
											}
										/>
										<DescList.Item
											label="Notes to driver"
											value={orderData && orderData.customerNotes}
										/>
									</DescList>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header
									extra={orderData && <PaymentStatusTag status={orderData.paymentStatus} />}
									bordered
								>
									Payment
								</Card.Header>
								<Card.Body>
									<DescList itemLayout="row" isLoading={queryResult.isLoading}>
										<DescList.Item
											label="Payment method"
											value={orderData && orderData.paymentMethod}
										/>
										<DescList.Item
											label="Subtotal"
											value={orderData && orderData.subTotalAmount.formattedAmount}
										/>
										{orderData && orderData.discountAmount && (
											<DescList.Item
												label={
													orderData.couponCode ? `Discount (${orderData.couponCode})` : 'Discount'
												}
												value={orderData.discountAmount.formattedAmount}
											/>
										)}
										<hr />
										<DescList.Item
											label="Total"
											value={
												orderData && (
													<span className="font-bold">{orderData.totalAmount.formattedAmount}</span>
												)
											}
										/>
									</DescList>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header bordered>Activity</Card.Header>
								<Card.Body>
									{orderData ? (
										orderData.events.length === 0 ? (
											<div className="w-full flex flex-col justify-center items-center p-6 gap-3">
												<HiOutlineSparkles
													className="w-9 h-9 text-gray-300"
													width={36}
													height={36}
												/>
												<span className="text-gray-600">No activity</span>
											</div>
										) : (
											<ul>
												{orderData.events.map((event) => (
													<li
														className="flex gap-1 relative pb-4 last:pb-0 group/item"
														key={event.id}
													>
														<span className="absolute top-3 left-1 -ml-px h-full w-0.5 bg-gray-200 group-last/item:hidden" />
														<div className="relative py-[6px]">
															<div className="w-2 h-2 rounded-full bg-gray-300 ring-8 ring-white" />
														</div>
														<div className="pl-2">
															<div className="text-sm">{event.title}</div>
															{event.payload && (
																<dl className="pt-1">
																	{Object.entries(event.payload).map(([field, value]) =>
																		isString(value) ? (
																			<div className="flex gap-1">
																				<dt className="text-xs text-gray-500">
																					{sentenceCase(field)}:
																				</dt>
																				<dd className="text-xs text-gray-500">{value}</dd>
																			</div>
																		) : null
																	)}
																</dl>
															)}
															{event.createdAt && (
																<div>
																	<time
																		dateTime={event.createdAt}
																		className="text-xs text-gray-500"
																	>
																		{formatDate(event.createdAt, { formatType: 'dateAndTime' })}
																	</time>
																</div>
															)}
														</div>
													</li>
												))}
											</ul>
										)
									) : (
										<Skeleton />
									)}
								</Card.Body>
							</Card>
						</section>
					</div>
				</Content>
			);
		},
	}),
});

const isOrderFinal = (orderStatus: OrderStatus) =>
	[OrderStatus.Cancelled, OrderStatus.Completed, OrderStatus.Failed].includes(orderStatus);
