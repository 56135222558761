import { useCustom, useCustomMutation } from '@pankod/refine-core';
import { Button, SelectField, TextField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { type CancellationReason, OrderActor, Status } from '~/api';

export const OrderCancelForm = (props: { orderId: string; onSuccess: () => void }) => {
	const { data, isLoading } = useCustom<Array<Pick<CancellationReason, 'code' | 'description'>>>({
		method: 'get',
		url: '',
		metaData: {
			operation: 'cancellationReasons',
			fields: ['code', 'description'],
			variables: {
				where: {
					type: 'CancellationReasonWhereInput',
					value: {
						for: {
							equals: OrderActor.Operator,
						},
						status: {
							equals: Status.Active,
						},
					},
				},
			},
		},
	});

	const [reasonCode, setReasonCode] = React.useState('');
	const [comments, setComments] = React.useState('');

	const mutation = useCustomMutation();

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault();

				if (reasonCode) {
					mutation.mutate(
						{
							method: 'post',
							url: '',
							values: {},
							metaData: {
								operation: 'cancelOrder',
								fields: ['id'],
								variables: {
									id: {
										type: 'ID',
										required: true,
										value: props.orderId,
									},
									reasonCode: {
										type: 'String',
										value: reasonCode,
									},
									comments: {
										type: 'String',
										value: comments,
									},
								},
							},
							successNotification: {
								message: 'Order cancelled',
								type: 'success',
							},
						},
						{
							onSuccess: props.onSuccess,
						}
					);
				}
			}}
		>
			<div className="flex flex-col gap-3 py-3">
				{isLoading ? (
					<TextField disabled placeholder="Loading..." label="Cancel reason" />
				) : (
					<SelectField<string>
						options={
							data
								? data.data.map((reason) => ({
										label: reason.description,
										value: reason.code,
								  }))
								: []
						}
						value={reasonCode}
						onValue={setReasonCode}
						label="Cancel reason"
						required
					/>
				)}
				<TextField label="Comment" value={comments} onValue={setComments} disabled={isLoading} />
			</div>
			<div className="flex flex-row-reverse">
				<Button
					type="submit"
					variant="danger"
					size="sm"
					disabled={isLoading || !reasonCode}
					loading={mutation.isLoading}
				>
					Cancel Order
				</Button>
			</div>
		</form>
	);
};
