import { useCustomMutation, useNotification } from '@pankod/refine-core';
import { Button, Dialog, NumberField, SelectField, TextField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { AdjustmentType } from '~/api';

type WalletAdjustmentDialogProps = {
	onSuccess: () => void;
	userId: string;
};

export const WalletAdjustmentDialog = ({
	button,
	...props
}: WalletAdjustmentDialogProps & { button: React.ReactElement }) => {
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);

	return (
		<Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
			<Dialog.Trigger asChild>{button}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.Content>
					<WalletAdjustmentForm
						{...props}
						onSuccess={() => {
							props.onSuccess();
							setIsDialogOpen(false);
						}}
					/>
					<Dialog.CloseButton />
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

const WalletAdjustmentForm = (props: WalletAdjustmentDialogProps) => {
	const mutation = useCustomMutation();
	const { open } = useNotification();

	const [amount, setAmount] = React.useState('');
	const [adjustmentType, setAdjustmentType] = React.useState('');
	const [remarks, setRemarks] = React.useState('');

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault();

				mutation.mutate(
					{
						method: 'post',
						url: '',
						values: {},
						metaData: {
							operation: 'adjustWalletBalance',
							fields: ['id'],
							variables: {
								data: {
									type: 'AdjustWalletBalanceInput',
									required: true,
									value: {
										amount,
										adjustmentType,
										remarks,
										userId: props.userId,
									},
								},
							},
						},
					},
					{
						onSuccess: () => {
							props.onSuccess();
							open &&
								open({
									message: 'Wallet balance adjusted!',
									type: 'success',
								});
						},
					}
				);
			}}
		>
			<div className="flex flex-col gap-3">
				<Dialog.Title>Wallet Adjustment</Dialog.Title>
				<SelectField
					label="Adjustment Type"
					options={Object.values(AdjustmentType).map((type: string) => ({
						label: type,
						value: type,
					}))}
					value={adjustmentType}
					onValue={setAdjustmentType}
					required
				/>
				<NumberField label="Amount (RM)" value={amount} onValue={setAmount} required />
				<TextField label="Remarks" value={remarks} onValue={setRemarks} />
			</div>

			<div className="flex flex-row-reverse gap-3 pt-3">
				<Button type="submit" variant="solid" size="sm" loading={mutation.isLoading}>
					Create
				</Button>
			</div>
		</form>
	);
};
