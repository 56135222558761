import { useCustomMutation } from '@pankod/refine-core';
import { Button, DateTimeField, formatDateUtc } from '@scalingworks/react-admin-ui';
import * as React from 'react';

export const OrderSetEstimatedTimeForm = (props: { orderId: string; onSuccess: () => void }) => {
	const mutation = useCustomMutation();
	const [estimatedTime, setEstimatedTime] = React.useState<Date>();

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault();

				if (estimatedTime) {
					mutation.mutate(
						{
							method: 'post',
							url: '',
							values: {},
							metaData: {
								operation: 'setEstimatedArrivalTime',
								fields: ['id'],
								variables: {
									orderId: {
										type: 'String',
										required: true,
										value: props.orderId,
									},
									estimatedArrivalTime: {
										type: 'DateTime',
										required: true,
										value: formatDateUtc(estimatedTime),
									},
								},
							},
							successNotification: {
								message: 'Estimated arrival time updated',
								type: 'success',
							},
						},
						{
							onSuccess: props.onSuccess,
						}
					);
				}
			}}
		>
			<div className="flex flex-col gap-3 py-3">
				<DateTimeField
					label="Estimated arrival time"
					value={estimatedTime}
					onValue={setEstimatedTime}
					required
				/>
				<div className="flex flex-row-reverse">
					<Button type="submit" variant="solid" size="sm" loading={mutation.isLoading}>
						Save
					</Button>
				</div>
			</div>
		</form>
	);
};
