import { Avatar, Skeleton } from '@scalingworks/react-admin-ui';
import { LinkToDetails } from '@scalingworks/refine-react-admin';
import { clsx } from 'clsx';
import { HiMail, HiPhone } from 'react-icons/hi';

export type PersonDetailsProps = {
	resourceName: string;
	person: {
		fullName: string;
		profilePicture?: string | null;
		email?: string | null;
		mobileNumber?: string | null;
		id: string;
	};
	subText?: React.ReactNode;
};

const PersonDetailsSkeleton = () => <PersonDetailsImpl />;

export const PersonDetails = Object.assign(
	function PersonDetails(props: PersonDetailsProps) {
		return <PersonDetailsImpl {...props} />;
	},
	{
		Skeleton: PersonDetailsSkeleton,
	}
);

const PersonDetailsImpl = ({ person, subText, resourceName }: Partial<PersonDetailsProps>) => (
	<div className={clsx('@container/person', !person && 'animate-pulse')}>
		<div className="flex flex-col gap-2 @sm/person:flex-row @sm/person:justify-between">
			<div className="flex items-center gap-2">
				{person ? (
					<Avatar text={person.fullName} imgSrc={person.profilePicture || undefined} size="md" />
				) : (
					<Avatar size="md" isLoading />
				)}
				{person && resourceName ? (
					<LinkToDetails resourceName={resourceName} resourceId={person.id}>
						<p className="text-sm font-medium">{person.fullName}</p>
						{subText}
					</LinkToDetails>
				) : (
					<Skeleton width="medium" />
				)}
			</div>
			<div className="flex flex-col gap-2">
				{person ? (
					person.email && (
						<a
							href={`mailto:${person.email}`}
							className="flex items-center gap-1 text-gray-500 hover:text-primary-900"
						>
							<HiMail className="w-5 h-5" width={20} height={20} />
							<span className="text-sm">{person.email}</span>
						</a>
					)
				) : (
					<div className="flex items-center gap-1 text-gray-500">
						<HiMail className="w-5 h-5" width={20} height={20} />
						<Skeleton width="medium" />
					</div>
				)}
				{person ? (
					person.mobileNumber && (
						<a
							href={`tel:${person.mobileNumber}`}
							className="flex items-center gap-1 text-gray-500 hover:text-primary-900"
						>
							<HiPhone className="w-5 h-5" width={20} height={20} />
							<span className="text-sm">{person.mobileNumber}</span>
						</a>
					)
				) : (
					<div className="flex items-center gap-1 text-gray-500">
						<HiPhone className="w-5 h-5" width={20} height={20} />
						<Skeleton width="medium" />
					</div>
				)}
			</div>
		</div>
	</div>
);
