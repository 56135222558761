import { useCustom, useCustomMutation } from '@pankod/refine-core';
import { Alert, Button, SelectField, TextField } from '@scalingworks/react-admin-ui';
import { sentenceCase } from '@scalingworks/refine-react-admin';
import * as React from 'react';

import { OrderTransitionStatus } from '~/api';

export const OrderChangeStatusForm = (props: { orderId: string; onSuccess: () => void }) => {
	const [status, setStatus] = React.useState('');
	const [waitingTimeDuration, setWaitingTimeDuration] = React.useState('');
	const [waitingTimeCost, setWaitingTimeCost] = React.useState('');
	const [isPrevented, setIsPrevented] = React.useState(false);

	const { data, isLoading } = useCustom<Array<OrderTransitionStatus>>({
		method: 'get',
		url: '',
		metaData: {
			operation: 'orderTransitionStatuses',
			variables: {
				id: {
					type: 'ID',
					required: true,
					value: props.orderId,
				},
			},
		},
		queryOptions: {
			onSuccess: (data) => {
				if (Array.isArray(data.data)) {
					const statuses = data.data.find((x) => x !== OrderTransitionStatus.Cancelled) || [];
					if (statuses.length === 1) {
						setStatus(statuses[0]);
					} else if (statuses.length === 0) {
						setIsPrevented(true);
					}
				}
			},
		},
	});

	const mutation = useCustomMutation();

	return isPrevented ? (
		<Alert type="danger" title="Change status is not allowed for this order." />
	) : (
		<form
			onSubmit={(ev) => {
				ev.preventDefault();
				if (status) {
					mutation.mutate(
						{
							method: 'post',
							url: '',
							values: {},
							metaData: {
								operation: 'transitionOrder',
								fields: ['id'],
								variables: {
									id: {
										type: 'ID',
										required: true,
										value: props.orderId,
									},
									status: {
										type: 'OrderTransitionStatus',
										required: true,
										value: status,
									},
									metadata: {
										type: 'JSON',
										required: false,
										value: status === OrderTransitionStatus.PickedUp ? {
											waitingTimeDuration: isNaN(Number(waitingTimeDuration)) ? 0 : Number(waitingTimeDuration),
											waitingTimeCost: isNaN(Number(waitingTimeCost)) ? 0 : Number(waitingTimeCost),
										} : undefined
									}
								},
							},
							successNotification: {
								message: 'Status updated',
								type: 'success',
							},
						},
						{
							onSuccess: props.onSuccess,
						}
					);
				}
			}}
		>
			<div className="flex flex-col gap-3 py-3">
				{isLoading ? (
					<TextField disabled placeholder="Loading..." label="New status" required />
				) : (
					<SelectField<string>
						label="New status"
						options={
							data
								? data.data.filter(x => x !== OrderTransitionStatus.Cancelled).map((s) => ({
										label: sentenceCase(s),
										value: s,
								  }))
								: []
						}
						value={status}
						onValue={setStatus}
						required
					/>
				)}

				{status === OrderTransitionStatus.PickedUp && (
					<>
					<TextField label="Waiting time (minutes)" value={waitingTimeDuration} onValue={setWaitingTimeDuration} />
					<TextField label="Waiting time cost" value={waitingTimeCost} onValue={setWaitingTimeCost} />
					</>
				)}
			</div>
			<div className="flex flex-row-reverse">
				<Button
					type="submit"
					variant="solid"
					size="sm"
					disabled={isLoading || !status}
					loading={mutation.isLoading}
				>
					Change status
				</Button>
			</div>
		</form>
	);
};
