export const notificationAudio = (function getNotificationAudioPlayyer() {
	const audio = new Audio('/notification.mp3');
	let confirmedAllowed = false;

	return {
		play() {
			audio.play();
		},
		requestPermission() {
			if (!confirmedAllowed) {
				const mutedAudio = new Audio('/notification.mp3');
				mutedAudio.muted = true;
				mutedAudio
					.play()
					.then(() => {
						confirmedAllowed = true;
					})
					.catch(() => {
						// noop
					});
			}
		},
	};
})();
