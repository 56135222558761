import {
	Button,
	IconButton,
	NumberInput,
	type NumberInputProps,
	assertExists,
	isNumber,
	omit,
	useAutoAnimate,
} from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import * as React from 'react';
import { HiPlus, HiX } from 'react-icons/hi';

import { type PriceTier, type TieredPrice, type TieredPriceCreateInputV2, getSdk } from '~/api';

import { resourceNames } from './resource-names';

const OnBlurNumberInput = (props: NumberInputProps) => {
	const [value, setValue] = React.useState(props.value);

	React.useEffect(() => {
		if (props.value !== value) {
			setValue(props.value);
		}
	}, [props.value]);

	return (
		<NumberInput
			{...props}
			value={value}
			onValue={setValue}
			onBlur={() => props.onValue?.(value)}
		/>
	);
};

const { defineFields, defineControl, defineCardSection, defineShowPage } =
	createHelpers<TieredPrice>({
		resourceName: resourceNames.tierPrice,
	});

const TiersControl = defineControl<'tiers'>({
	label: 'Tiers',
	component: function TiersControl({ value, setValue, disabled }) {
		const itemsWithIndex = React.useMemo(
			() =>
				Array.isArray(value)
					? value.map((item, index) => ({
							...item,
							index,
					  }))
					: value,
			[value]
		);

		const sortedItems = React.useMemo(
			() => itemsWithIndex && sortPriceTiers(itemsWithIndex),
			[itemsWithIndex]
		);

		const [listRef] = useAutoAnimate<HTMLTableSectionElement>({
			duration: 150,
		});

		return (
			<div className="overflow-x-auto">
				<table className="w-full min-w-full">
					<thead>
						<tr>
							<th className="text-sm text-left text-gray-600 font-medium p-1">Distance</th>
							<th className="text-sm text-left text-gray-600 font-medium p-1">Price (RM/km)</th>
							<th></th>
						</tr>
					</thead>
					<tbody ref={listRef}>
						{sortedItems &&
							sortedItems.map((item, indexAfterSort) => {
								const nextItem = sortedItems[indexAfterSort + 1];

								return (
									<tr key={item.index}>
										<td>
											<div className="flex items-center gap-2">
												<div className="w-24">
													<OnBlurNumberInput
														value={String(item.from)}
														onValue={(newValue) => {
															const newValueAsNumber = NumberInput.getNumberValue(newValue);

															if (isNumber(newValueAsNumber)) {
																const newSortedItems = sortPriceTiers(
																	(sortedItems || []).map((currentItem) =>
																		currentItem.index === item.index
																			? {
																					...currentItem,
																					from: newValueAsNumber,
																			  }
																			: currentItem
																	)
																);

																const newSortedItemsWithUpdatedValues = newSortedItems.map(
																	(item, index) => {
																		const nextItem = newSortedItems[index + 1];

																		if (nextItem) {
																			return {
																				...item,
																				to: nextItem.from,
																			};
																		}

																		return item;
																	}
																);

																setValue(
																	(value || []).map((currentItem, index) => {
																		const matchedItem = newSortedItemsWithUpdatedValues.find(
																			(item) => item.index === index
																		);

																		return matchedItem ? omit(matchedItem, ['index']) : currentItem;
																	})
																);
															}
														}}
														disabled={disabled}
														decimalPlaces={0}
													/>
												</div>
												<span>{nextItem ? `to ${nextItem.from}` : 'and above'}</span>
											</div>
										</td>
										<td>
											<div className="w-24 md:w-full">
												<OnBlurNumberInput
													onValue={(newPrice) => {
														setValue(
															(value || []).map((currentItem, index) => {
																if (index === item.index) {
																	return {
																		...currentItem,
																		price: newPrice,
																	};
																}
																return currentItem;
															})
														);
													}}
													value={item.price}
													decimalPlaces={2}
													allowTrailingZero
												/>
											</div>
										</td>
										<td className="px-2 py-1 text-right">
											<IconButton
												onClick={() =>
													setValue((value || []).filter((_, index) => index !== item.index))
												}
												disabled={disabled}
												aria-label="Remove"
											>
												<HiX />
											</IconButton>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
				{value && value.length > 0 ? (
					<div className="flex justify-end p-2">
						<IconButton
							onClick={() => {
								const lastItem = sortedItems && sortedItems[sortedItems.length - 1];
								setValue(
									lastItem && value
										? value.concat([{ from: Math.floor(lastItem.from + 1), price: lastItem.price }])
										: [{ from: 0, price: '10' }]
								);
							}}
							disabled={disabled}
							aria-label="Add"
						>
							<HiPlus />
						</IconButton>
					</div>
				) : (
					<div
						onClick={() => setValue([{ from: 0, price: '10' }])}
						className="flex justify-center items-center p-6"
					>
						<Button>Add Price by Distance</Button>
					</div>
				)}
			</div>
		);
	},
});

export const tierPriceResource = createResource({
	name: resourceNames.tierPrice,
	label: 'Tiered Prices',
	fields: defineFields([
		'id',
		'name',
		'basePrice',
		{
			tiers: ['from', 'to', 'price'],
		},
		'status',
	]),
	filterControls: {
		name: {
			type: 'text',
			config: {
				label: 'Name',
			},
			operator: 'contains',
		},
	},
	columns: ({ LinkToDetails, navigateToEdit, invokeDelete }) => [
		{
			id: 'name',
			header: 'Name',
			accessorKey: 'name',
			cell: (data) => {
				const name = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={data.row.original.id} className="font-semibold">
						{name}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'tiers',
			header: 'Tiers',
			accessorKey: 'tiers',
			enableSorting: false,
			cell: (data) => {
				const { tiers } = data.row.original;

				return tiers && tiers.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th className="text-left font-medium">Range (KM)</th>
								<th className="text-left font-medium">Price</th>
							</tr>
						</thead>
						<tbody>
							{tiers.map((tier, index) => (
								<tr key={index}>
									<td>{tier.to ? `${tier.from}-${tier.to}` : `${tier.from} and above`}</td>
									<td className="text-right">{tier.price}</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					'-'
				);
			},
		},
		{
			id: 'status',
			header: 'Status',
			accessorKey: 'status',
		},
		{
			id: 'actions',
			header: 'Actions',
			accessorKey: 'id',
			cell: (data) => {
				const tierId = data.cell.getValue<string>();

				return (
					<span className="flex justify-end items-center gap-2">
						<button
							onClick={() => navigateToEdit({ id: tierId })}
							className="text-primary-500 text-sm font-bold"
							type="button"
						>
							EDIT
						</button>
						<button
							onClick={() => invokeDelete({ id: tierId })}
							className="text-red-500 text-sm font-bold"
							type="button"
						>
							DELETE
						</button>
					</span>
				);
			},
		},
	],
	defaultValues: {
		name: '',
		basePrice: '',
	},
	dataProvider: {
		create: ({ client, variables }) =>
			getSdk(client)
				.createTierPrice({
					data: variables as TieredPriceCreateInputV2,
				})
				.then((res) => ({
					data: res.createOneTieredPrice,
				})),
		update: ({ client, variables, id }) =>
			getSdk(client)
				.updateTierPrice({
					data: variables,
					where: {
						id: id as string,
					},
				})
				.then((res) => {
					assertExists(res.updateOneTieredPrice, 'value returned by updateTierPrice is undefined');

					return {
						data: res.updateOneTieredPrice,
					};
				}),
	},
	controls: {
		components: {
			basePrice: {
				type: 'number',
				config: {
					label: 'Base price',
				},
			},
			tiers: TiersControl,
		},
		componentConfigDefaults: {
			required: true,
		},
		sections: [{ items: ['name', 'basePrice', { field: 'tiers', span: 2 }] }],
	},
	show: defineShowPage({
		title: (data) => data.name,
		sections: [
			defineCardSection({
				title: 'Details',
				fields: [
					'name',
					'status',
					'basePrice',
					{
						tiers: ['from', 'to', 'price'],
					},
				],
				displays: {
					tiers: {
						label: 'Tiers',
						render: (data) => {
							const tiers = data.tiers;

							if (!tiers || tiers.length == 0) {
								return null;
							}

							const sortedTiers = sortPriceTiers(tiers);

							return (
								<ul>
									{sortedTiers.map((tier, index) => (
										<li key={index}>
											{tier.from} {tier.to ? `to ${tier.to}` : `and above`} : RM{tier.price} / km
										</li>
									))}
								</ul>
							);
						},
					},
				},
			}),
		],
	}),
});

const sortPriceTiers = <Item extends PriceTier>(prices: Array<Item>) =>
	[...prices].sort((a, b) => a.from - b.from);
