import { useCustom, useCustomMutation, useNotification } from '@pankod/refine-core';
import {
	Avatar,
	Button,
	Dialog,
	Spinner,
	Table,
	TextInput,
	useAutoAnimate,
} from '@scalingworks/react-admin-ui';
import { createHelpers } from '@scalingworks/refine-react-admin';
import * as React from 'react';
import { HiCheck, HiSearch } from 'react-icons/hi';

import type { PaginatationMetadata, ServiceProvider } from '~/api';

import { resourceNames } from '../resource-names';

const driverHelpers = createHelpers<ServiceProvider>({
	resourceName: resourceNames.driver,
});

type AssignDriverDialogProps = {
	orderId: string;
	initialDriverId?: string;
	onSuccess: () => void;
};

export const AssignDriverDialog = ({
	button,
	...props
}: AssignDriverDialogProps & { button: React.ReactElement }) => {
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);

	return (
		<Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
			<Dialog.Trigger asChild>{button}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.Content>
					<AssignDriverForm
						{...props}
						onSuccess={() => {
							props.onSuccess();
							setIsDialogOpen(false);
						}}
					/>
					<Dialog.CloseButton />
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

const AssignDriverForm = (props: AssignDriverDialogProps) => {
	const [searchKey, setSearchKey] = React.useState('');

	const { data, isLoading } = useCustom<{
		items: Array<ServiceProvider>;
		metadata: PaginatationMetadata;
	}>({
		method: 'get',
		url: '',
		metaData: {
			operation: 'availableServiceProviders',
			variables: {
				orderId: {
					type: 'String',
					required: true,
					value: props.orderId,
				},
				search: {
					type: 'String',
					value: searchKey,
				},
			},
			fields: [
				{
					items: driverHelpers.defineFields([
						'id',
						'fullName',
						'rating',
						'ordersCount',
						'profilePicture',
						'mobileNumber',
						'email',
					]),
				},
			],
		},
	});

	const [driverId, setDriverId] = React.useState(() => props.initialDriverId || '');

	const [selectedDriver, setSelectedDriver] = React.useState<ServiceProvider | undefined>(
		undefined
	);

	const drivers = React.useMemo(() => {
		// the logic here is to make sure selected driver will appear first in the list.
		// however it is possible that after search, selected river no longer in the list, hence
		// this ugly logic

		const driverData = data?.data.items;

		const dataWithoutSelected =
			driverData && (driverId ? driverData.filter((d) => d.id !== driverId) : driverData);

		const selectedData =
			selectedDriver || (driverData && driverData.find((d) => d.id === driverId));

		const selectedDataAsArray = selectedData ? [selectedData] : [];

		return selectedData
			? dataWithoutSelected
				? selectedDataAsArray.concat(dataWithoutSelected)
				: selectedDataAsArray
			: dataWithoutSelected;
	}, [data, selectedDriver]);

	const mutation = useCustomMutation();
	const { open } = useNotification();

	const [mobileListRef] = useAutoAnimate<HTMLDivElement>({
		duration: 150,
	});
	const [listRef] = useAutoAnimate<HTMLTableSectionElement>({
		duration: 150,
	});

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault();
				mutation.mutate(
					{
						method: 'post',
						url: '',
						values: {},
						metaData: {
							operation: 'assignOrder',
							fields: ['id'],
							variables: {
								id: {
									type: 'ID',
									required: true,
									value: props.orderId,
								},
								serviceProviderId: {
									type: 'String',
									required: true,
									value: driverId,
								},
							},
						},
					},
					{
						onSuccess: () => {
							props.onSuccess();
							open &&
								open({
									message: 'Driver assigned',
									type: 'success',
								});
						},
					}
				);
			}}
		>
			<Dialog.Title>Assign Driver</Dialog.Title>
			<div className="py-2 group sm:min-w-[80vw] lg:min-w-[70vw]">
				<TextInput
					value={searchKey}
					onValue={setSearchKey}
					prefix={<HiSearch className="text-gray-500 group-focus-within:text-primary-600" />}
					suffix={searchKey && isLoading ? <Spinner /> : null}
					type="search"
				/>
			</div>
			<div className="flex flex-col gap-3 py-2 md:hidden" ref={mobileListRef}>
				{drivers
					? drivers.map((driver) => {
							const selected = driver.id === driverId;
							return (
								<div className="flex justify-between items-center" key={driver.id}>
									<div className="flex items-center gap-2 px-2">
										<Avatar
											text={driver.fullName}
											imgSrc={driver.profilePicture || undefined}
											className="flex-shrink-0"
										/>
										<div>
											<div>{driver.fullName}</div>
											<div className="text-xs">{driver.mobileNumber}</div>
										</div>
									</div>
									{selected ? (
										<div className="px-2">
											<HiCheck className="w-5 h-5 text-green-500" width={20} height={20} />
										</div>
									) : (
										<Button
											onClick={() => {
												setDriverId(driver.id);
												setSelectedDriver(driver);
											}}
											size="sm"
											variant="twoTone"
										>
											Select
										</Button>
									)}
								</div>
							);
					  })
					: 'Loading...'}
			</div>
			<div className="hidden md:block">
				<Table>
					<Table.Thead>
						<Table.Tr>
							<Table.Th>Full name</Table.Th>
							<Table.Th>Mobile</Table.Th>
							<Table.Th>Email</Table.Th>
							<Table.Th>Total orders</Table.Th>
							<Table.Th>Selected</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody ref={listRef}>
						{drivers
							? drivers.map((driver) => {
									const selected = driver.id === driverId;

									return (
										<Table.Tr className={selected ? 'bg-primary-100' : undefined} key={driver.id}>
											<Table.Td>
												<div className="flex items-center gap-2">
													<Avatar
														text={driver.fullName}
														imgSrc={driver.profilePicture || undefined}
														className="flex-shrink-0"
													/>
													{driver.fullName}
												</div>
											</Table.Td>
											<Table.Td>{driver.mobileNumber}</Table.Td>
											<Table.Td>{driver.email}</Table.Td>
											<Table.Td>{driver.ordersCount}</Table.Td>
											<Table.Td>
												{selected ? (
													<div className="flex justify-center">
														<HiCheck className="w-5 h-5 text-green-500" width={20} height={20} />
													</div>
												) : (
													<div className="flex justify-center">
														<Button
															onClick={() => {
																setDriverId(driver.id);
																setSelectedDriver(driver);
															}}
															size="sm"
															variant="twoTone"
														>
															Select
														</Button>
													</div>
												)}
											</Table.Td>
										</Table.Tr>
									);
							  })
							: Array.from({ length: 3 }).map((_, i) => (
									<Table.Tr key={i}>
										<Table.Td>
											<div className="h-5 bg-gray-200 animate-pulse w-10" />
										</Table.Td>
										<Table.Td>
											<div className="h-5 bg-gray-200 animate-pulse w-10" />
										</Table.Td>
										<Table.Td>
											<div className="h-5 bg-gray-200 animate-pulse w-10" />
										</Table.Td>
										<Table.Td>
											<div className="h-5 bg-gray-200 animate-pulse w-10" />
										</Table.Td>
										<Table.Td>
											<div className="h-5 bg-gray-200 animate-pulse w-10" />
										</Table.Td>
									</Table.Tr>
							  ))}
					</Table.Tbody>
				</Table>
			</div>
			<div className="flex flex-row-reverse gap-3 pt-3">
				<Button
					type="submit"
					variant="solid"
					size="sm"
					loading={mutation.isLoading}
					disabled={!driverId}
				>
					Assign
				</Button>
			</div>
		</form>
	);
};
