import { Tag, type TagProps } from '@scalingworks/react-admin-ui';

import type { PaymentStatus } from '~/api';

const colorByPaymentStatus: Record<PaymentStatus, TagProps['color']> = {
	Authorized: 'blue',
	Refunded: 'gray',
	Cancelled: 'gray',
	Pending: 'amber',
	RequiresAction: 'red',
	Succeeded: 'green',
	Failed: 'red',
};

export const PaymentStatusTag = (props: { status: PaymentStatus }) => (
	<Tag color={colorByPaymentStatus[props.status]}>{props.status}</Tag>
);
