import { SelectField, assertExists } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { type AdminRole, type AdminUser, Status, getSdk } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineControl, defineCardSection, defineShowPage } = createHelpers<AdminUser>(
	{
		resourceName: resourceNames.adminUser,
	}
);

const adminRoleHelpers = createHelpers<AdminRole>({
	resourceName: resourceNames.adminRole,
});

const defaultValues = {
	name: '',
	email: '',
	password: '',
	roleId: undefined,
};

export const adminUserResource = createResource({
	name: resourceNames.adminUser,
	label: 'Admin Users',
	fields: defineFields([
		'id',
		'name',
		'email',
		'status',
		'roleId',
		'createdAt',
		'updatedAt',
		{
			role: ['id', 'code', 'name'],
		},
	]),
	defaultValues,
	formatBeforeSubmit: (data) => ({
		...data,
		status: Status.Active,
	}),
	dataProvider: {
		create: ({ client, variables }) =>
			getSdk(client)
				.createAdminUser({
					data: variables as Required<typeof defaultValues>,
				})
				.then((res) => ({
					data: res.createOneAdminUser,
				})),
		update: ({ client, variables, id }) =>
			getSdk(client)
				.updateAdminUser({
					data: variables,
					where: {
						id: id as string,
					},
				})
				.then((res) => {
					assertExists(res.updateOneAdminUser, 'value returned by updateOneAdminUser is undefined');

					return {
						data: res.updateOneAdminUser,
					};
				}),
	},
	controls: {
		components: ({ action }) => {
			return {
				email: {
					type: 'text',
					config: {
						label: 'Email',
						type: 'email',
					},
				},
				password:
					action === 'create'
						? {
								type: 'text',
								config: {
									label: 'Password',
								},
						  }
						: false,
				roleId: defineControl<'roleId'>({
					label: 'Role',
					component: function RoleField(props) {
						const { options } = adminRoleHelpers.useSelect({
							metaData: {
								fields: ['id', 'name'],
							},
							optionLabel: 'name',
							optionValue: 'id',
						});

						return (
							<SelectField
								id="role"
								label="Role"
								value={props.value ? String(props.value) : ''}
								onValue={(val) => props.setValue(val as string)}
								placeholder="Please select"
								options={options}
								required
								disabled={props.disabled}
								key={options.length} // remount when options is loaded
							/>
						);
					},
				}),
			};
		},
		componentConfigDefaults: {
			required: true,
		},
	},
	defaultPageSize: 10,
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	allowSearch: true,
	allowDelete: true,
	columns: ({ LinkToDetails, navigateToEdit }) => [
		{
			id: 'name',
			header: 'Name',
			accessorKey: 'name',
			cell: (data) => {
				const name = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={data.row.original.id} className="font-semibold">
						{name}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'email',
			header: 'Email',
			accessorKey: 'email',
		},
		{
			id: 'role',
			header: 'Role',
			accessorFn: (data) => data.role?.name,
		},
		{
			id: 'status',
			header: 'Status',
			accessorKey: 'status',
			enableSorting: false,
			// cell: (props) => {
			//   const cellVal = props.cell.getValue<string>();
			//   return (
			//     <Tag color={cellVal.toLowerCase().includes('inactive') ? 'red' : 'primary'} variant="light">
			//       {props.cell.getValue<string>()}
			//     </Tag>
			//   );
			// },
		},
		{
			id: 'actions',
			header: () => <div className="text-right">Actions</div>,
			accessorKey: 'id',
			enableSorting: false,
			cell: (data) => {
				const userId = data.cell.getValue<string>();
				return (
					<span className="flex justify-end items-center gap-2">
						<button
							onClick={() => navigateToEdit({ id: userId })}
							className="text-primary-500 text-sm font-bold"
							type="button"
						>
							EDIT
						</button>
					</span>
				);
			},
		},
	],
	show: defineShowPage({
		sections: [
			defineCardSection({
				title: 'General',
				fields: [
					'name',
					'email',
					'status',
					{
						role: ['name'],
					},
				],
				displays: {
					role: {
						label: 'Role',
						render: (user) => (user.role && user.role.name) || '-',
					},
				},
			}),
		],
	}),
});
