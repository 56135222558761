import { Button, Dialog, Spinner } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import type { Waypoint } from '~/api';

const GoogleMap = React.lazy(() =>
	import('../../components/google-map-with-markers').then((m) => ({
		default: m.GoogleMapWithMarkers,
	}))
);

export const ShowMapButton = (props: { points: Array<Waypoint> }) => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>
				<Button size="sm">Show Map</Button>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.Content className="h-[90vh] w-[90vw]">
					<React.Suspense
						fallback={
							<div className="flex justify-center items-center p-12">
								<Spinner />
							</div>
						}
					>
						<GoogleMap points={props.points} />
					</React.Suspense>
					<Dialog.CloseButton />
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
