import routerProvider from '@pankod/refine-react-router-v6';
import {
	createRefineAdminApp,
	defineCustomPage,
	groupResources,
} from '@scalingworks/refine-react-admin';
import '@scalingworks/refine-react-admin/styles.css';
import { createRoot } from 'react-dom/client';
import { HiOutlineChartBar, HiOutlineCog, HiOutlineSpeakerphone } from 'react-icons/hi';
import 'tailwindcss/tailwind.css';

import logo from './assets/logo.png';
import { DashboardPage } from './pages/dashboard';
import { adminRoleResource } from './resources/admin-role-resource';
import { adminUserResource } from './resources/admin-user-resource';
import { customerResource } from './resources/customer-resource';
import { discountCouponResource } from './resources/discount-coupon-resource';
import { orderResource } from './resources/order-resource';
import { rateSetupResource } from './resources/rate-setup-resource';
import { serviceProviderResource } from './resources/service-provider-resource';
import { tierPriceResource } from './resources/tier-price-resource';

const RefineApp = createRefineAdminApp({
	graphqlUrl: `${import.meta.env.VITE_API_BASE_URL}/graphql`,
	sidebarHeader: (
		<div className="px-4 pb-3">
			<img src={logo} alt="DD Drive" width={168} height={51} />
		</div>
	),
	loginHeader: (
		<div className="flex flex-col items-center gap-3 pb-3">
			<img src={logo} alt="DD Drive" width={300} className="invert opacity-80" />
			<h1 className="text-2xl text-center font-bold">Login to DD Drive Admin Portal</h1>
		</div>
	),
	loginDefaults: {
		userName: 'admin@demo.com',
		password: 'abcd1234',
	},
	resources: [
		orderResource,
		customerResource,
		serviceProviderResource,
		...groupResources({
			groupName: 'marketing',
			icon: <HiOutlineSpeakerphone />,
			label: 'Marketing',
			resources: [discountCouponResource],
		}),
		...groupResources({
			groupName: 'settings',
			icon: <HiOutlineCog />,
			label: 'Settings',
			resources: [rateSetupResource, tierPriceResource, adminUserResource, adminRoleResource],
		}),
	],
	DashboardPage: defineCustomPage({
		title: 'Overview',
		render: () => <DashboardPage />,
		icon: <HiOutlineChartBar />,
	}),
	design: {
		sidebarVariant: 'primary',
	},
	routerProvider,
});

createRoot(document.getElementById('root')!).render(<RefineApp />);
