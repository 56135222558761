import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type Account = {
  __typename?: 'Account';
  accessToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type AccountCreateManyUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountCreateManyUserInputEnvelope = {
  data: Array<AccountCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AccountCreateOrConnectWithoutUserInput = {
  create: AccountUncheckedCreateWithoutUserInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccountOrderByWithRelationInput = {
  accessToken?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  providerType?: InputMaybe<SortOrder>;
  refreshToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountProviderTypeProviderAccountIdCompoundUniqueInput = {
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
};

export enum AccountScalarFieldEnum {
  AccessToken = 'accessToken',
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  Id = 'id',
  ProviderAccountId = 'providerAccountId',
  ProviderType = 'providerType',
  RefreshToken = 'refreshToken',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type AccountUncheckedCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccountCreateManyUserInputEnvelope>;
};

export type AccountUncheckedCreateWithoutUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  accessToken?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  providerAccountId?: InputMaybe<StringFilter>;
  providerType?: InputMaybe<StringFilter>;
  refreshToken?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccountWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  providerType_providerAccountId?: InputMaybe<AccountProviderTypeProviderAccountIdCompoundUniqueInput>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AdjustWalletBalanceInput = {
  adjustmentType: AdjustmentType;
  amount: Scalars['Decimal'];
  remarks?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export enum AdjustmentType {
  Decrease = 'Decrease',
  Increase = 'Increase'
}

export type AdminLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  action?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resource?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AdminPermissionOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resource?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminPermissionWhereInput = {
  AND?: InputMaybe<Array<AdminPermissionWhereInput>>;
  NOT?: InputMaybe<Array<AdminPermissionWhereInput>>;
  OR?: InputMaybe<Array<AdminPermissionWhereInput>>;
  action?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resource?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  _count?: Maybe<AdminRoleCount>;
  admins: Array<AdminUser>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
};


export type AdminRoleAdminsArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};

export type AdminRoleCount = {
  __typename?: 'AdminRoleCount';
  admins: Scalars['Int'];
};

export type AdminRoleCreateInputV2 = {
  admins?: InputMaybe<AdminUserCreateNestedManyWithoutRoleInput>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type AdminRoleOrderByWithRelationInput = {
  admins?: InputMaybe<AdminUserOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  permissions?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminRoleRelationFilter = {
  is?: InputMaybe<AdminRoleWhereInput>;
  isNot?: InputMaybe<AdminRoleWhereInput>;
};

export type AdminRoleUpdateInputV2 = {
  admins?: InputMaybe<AdminUserUpdateManyWithoutRoleNestedInput>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
};

export type AdminRoleWhereInput = {
  AND?: InputMaybe<Array<AdminRoleWhereInput>>;
  NOT?: InputMaybe<Array<AdminRoleWhereInput>>;
  OR?: InputMaybe<Array<AdminRoleWhereInput>>;
  admins?: InputMaybe<AdminUserListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRoleWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  _count?: Maybe<AdminUserCount>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<AdminRole>;
  roleId?: Maybe<Scalars['ID']>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  walletBalanceAdjustments: Array<WalletBalanceAdjustment>;
};


export type AdminUserWalletBalanceAdjustmentsArgs = {
  cursor?: InputMaybe<WalletBalanceAdjustmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletBalanceAdjustmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletBalanceAdjustmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
};

export type AdminUserCount = {
  __typename?: 'AdminUserCount';
  walletBalanceAdjustments: Scalars['Int'];
};

export type AdminUserCreateInputV2 = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentUncheckedCreateNestedManyWithoutCreatedByInput>;
};

export type AdminUserCreateManyRoleInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type AdminUserCreateManyRoleInputEnvelope = {
  data: Array<AdminUserCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AdminUserCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminUserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminUserCreateManyRoleInputEnvelope>;
};

export type AdminUserCreateOrConnectWithoutRoleInput = {
  create: AdminUserUncheckedCreateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserCreateWithoutRoleInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutAdminInput;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentCreateNestedManyWithoutCreatedByInput>;
};

export type AdminUserListRelationFilter = {
  every?: InputMaybe<AdminUserWhereInput>;
  none?: InputMaybe<AdminUserWhereInput>;
  some?: InputMaybe<AdminUserWhereInput>;
};

export type AdminUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdminUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  role?: InputMaybe<AdminRoleOrderByWithRelationInput>;
  roleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentOrderByRelationAggregateInput>;
};

export type AdminUserRelationFilter = {
  is?: InputMaybe<AdminUserWhereInput>;
  isNot?: InputMaybe<AdminUserWhereInput>;
};

export enum AdminUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  ProfilePicture = 'profilePicture',
  RoleId = 'roleId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type AdminUserScalarWhereInput = {
  AND?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  OR?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUserUncheckedCreateWithoutRoleInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentUncheckedCreateNestedManyWithoutCreatedByInput>;
};

export type AdminUserUncheckedUpdateManyWithoutAdminsInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type AdminUserUncheckedUpdateWithoutRoleInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentUncheckedUpdateManyWithoutCreatedByNestedInput>;
};

export type AdminUserUpdateInputV2 = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentUncheckedUpdateManyWithoutCreatedByNestedInput>;
};

export type AdminUserUpdateManyWithWhereWithoutRoleInput = {
  data: AdminUserUncheckedUpdateManyWithoutAdminsInput;
  where: AdminUserScalarWhereInput;
};

export type AdminUserUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminUserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminUserCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  set?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  update?: InputMaybe<Array<AdminUserUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<AdminUserUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<AdminUserUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type AdminUserUpdateWithWhereUniqueWithoutRoleInput = {
  data: AdminUserUncheckedUpdateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserUpsertWithWhereUniqueWithoutRoleInput = {
  create: AdminUserUncheckedCreateWithoutRoleInput;
  update: AdminUserUncheckedUpdateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserWhereInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<AdminRoleRelationFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentListRelationFilter>;
};

export type AdminUserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateCancellationReason = {
  __typename?: 'AggregateCancellationReason';
  _avg?: Maybe<CancellationReasonAvgAggregate>;
  _count?: Maybe<CancellationReasonCountAggregate>;
  _max?: Maybe<CancellationReasonMaxAggregate>;
  _min?: Maybe<CancellationReasonMinAggregate>;
  _sum?: Maybe<CancellationReasonSumAggregate>;
};

export type AggregateCountry = {
  __typename?: 'AggregateCountry';
  _avg?: Maybe<CountryAvgAggregate>;
  _count?: Maybe<CountryCountAggregate>;
  _max?: Maybe<CountryMaxAggregate>;
  _min?: Maybe<CountryMinAggregate>;
  _sum?: Maybe<CountrySumAggregate>;
};

export type AggregateState = {
  __typename?: 'AggregateState';
  _avg?: Maybe<StateAvgAggregate>;
  _count?: Maybe<StateCountAggregate>;
  _max?: Maybe<StateMaxAggregate>;
  _min?: Maybe<StateMinAggregate>;
  _sum?: Maybe<StateSumAggregate>;
};

export type AggregateVehicleMake = {
  __typename?: 'AggregateVehicleMake';
  _count?: Maybe<VehicleMakeCountAggregate>;
  _max?: Maybe<VehicleMakeMaxAggregate>;
  _min?: Maybe<VehicleMakeMinAggregate>;
};

export type AppRemoteConfig = {
  __typename?: 'AppRemoteConfig';
  appUpdateAvailability: AppUpdateAvailability;
  cancellationReasons?: Maybe<Array<CancellationReasonOption>>;
  helpCenter?: Maybe<HelpCenter>;
  maximumDropOffsAllowed?: Maybe<Scalars['Int']>;
  shouldPromptForAppUpdate: Scalars['Boolean'];
  urls?: Maybe<Urls>;
};

export enum AppType {
  Consumer = 'Consumer',
  ServiceProvider = 'ServiceProvider'
}

export enum AppUpdateAvailability {
  Available = 'Available',
  ForceUpgradeRequired = 'ForceUpgradeRequired',
  NotAvailable = 'NotAvailable'
}

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  refreshExpiresIn: Scalars['Float'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type AuthUserUnion = AdminUser | Customer | ServiceProvider;

export enum BalanceType {
  Cash = 'Cash',
  Wallet = 'Wallet'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolderName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type CalculateOrderInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  destination: WaypointInput;
  midStops?: InputMaybe<Array<WaypointInput>>;
  origin: WaypointInput;
  scheduledPickupAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<OrderType>;
};

export type CancellationReason = {
  __typename?: 'CancellationReason';
  _count?: Maybe<CancellationReasonCount>;
  code: Scalars['String'];
  description: Scalars['String'];
  for: OrderActor;
  id: Scalars['ID'];
  status: Status;
  translation?: Maybe<CancellationReasonTranslation>;
  translations: Array<CancellationReasonTranslation>;
};


export type CancellationReasonTranslationArgs = {
  localeCode: LocaleCode;
};


export type CancellationReasonTranslationsArgs = {
  cursor?: InputMaybe<CancellationReasonTranslationWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonTranslationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonTranslationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonTranslationWhereInput>;
};

export type CancellationReasonAvgAggregate = {
  __typename?: 'CancellationReasonAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CancellationReasonAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CancellationReasonCount = {
  __typename?: 'CancellationReasonCount';
  translations: Scalars['Int'];
};

export type CancellationReasonCountAggregate = {
  __typename?: 'CancellationReasonCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  for: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CancellationReasonCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonCreateManyInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  for: OrderActor;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
};

export type CancellationReasonGroupBy = {
  __typename?: 'CancellationReasonGroupBy';
  _avg?: Maybe<CancellationReasonAvgAggregate>;
  _count?: Maybe<CancellationReasonCountAggregate>;
  _max?: Maybe<CancellationReasonMaxAggregate>;
  _min?: Maybe<CancellationReasonMinAggregate>;
  _sum?: Maybe<CancellationReasonSumAggregate>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  for: OrderActor;
  id: Scalars['Int'];
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type CancellationReasonMaxAggregate = {
  __typename?: 'CancellationReasonMaxAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  for?: Maybe<OrderActor>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CancellationReasonMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonMinAggregate = {
  __typename?: 'CancellationReasonMinAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  for?: Maybe<OrderActor>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CancellationReasonMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonOption = {
  __typename?: 'CancellationReasonOption';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type CancellationReasonOrderByWithAggregationInput = {
  _avg?: InputMaybe<CancellationReasonAvgOrderByAggregateInput>;
  _count?: InputMaybe<CancellationReasonCountOrderByAggregateInput>;
  _max?: InputMaybe<CancellationReasonMaxOrderByAggregateInput>;
  _min?: InputMaybe<CancellationReasonMinOrderByAggregateInput>;
  _sum?: InputMaybe<CancellationReasonSumOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  translations?: InputMaybe<CancellationReasonTranslationOrderByRelationAggregateInput>;
};

export type CancellationReasonRelationFilter = {
  is?: InputMaybe<CancellationReasonWhereInput>;
  isNot?: InputMaybe<CancellationReasonWhereInput>;
};

export enum CancellationReasonScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Description = 'description',
  For = 'for',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CancellationReasonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  for?: InputMaybe<EnumOrderActorWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type CancellationReasonSumAggregate = {
  __typename?: 'CancellationReasonSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type CancellationReasonSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CancellationReasonTranslation = {
  __typename?: 'CancellationReasonTranslation';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationBaseIdLocaleCodeCompoundUniqueInput = {
  baseId: Scalars['Int'];
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationCreateManyCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope = {
  data: Array<CancellationReasonTranslationCreateManyCancellationReasonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput = {
  create: CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationCreateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationListRelationFilter = {
  every?: InputMaybe<CancellationReasonTranslationWhereInput>;
  none?: InputMaybe<CancellationReasonTranslationWhereInput>;
  some?: InputMaybe<CancellationReasonTranslationWhereInput>;
};

export type CancellationReasonTranslationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CancellationReasonTranslationOrderByWithRelationInput = {
  baseId?: InputMaybe<SortOrder>;
  cancellationReason?: InputMaybe<CancellationReasonOrderByWithRelationInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  localeCode?: InputMaybe<SortOrder>;
};

export enum CancellationReasonTranslationScalarFieldEnum {
  BaseId = 'baseId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  LocaleCode = 'localeCode',
  UpdatedAt = 'updatedAt'
}

export type CancellationReasonTranslationScalarWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  baseId?: InputMaybe<IntFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  localeCode?: InputMaybe<StringFilter>;
};

export type CancellationReasonTranslationUncheckedCreateNestedManyWithoutCancellationReasonInput = {
  connect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput>>;
  create?: InputMaybe<Array<CancellationReasonTranslationCreateWithoutCancellationReasonInput>>;
  createMany?: InputMaybe<CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope>;
};

export type CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationUncheckedUpdateManyWithoutCancellationReasonNestedInput = {
  connect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput>>;
  create?: InputMaybe<Array<CancellationReasonTranslationCreateWithoutCancellationReasonInput>>;
  createMany?: InputMaybe<CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope>;
  delete?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  set?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  update?: InputMaybe<Array<CancellationReasonTranslationUpdateWithWhereUniqueWithoutCancellationReasonInput>>;
  updateMany?: InputMaybe<Array<CancellationReasonTranslationUpdateManyWithWhereWithoutCancellationReasonInput>>;
  upsert?: InputMaybe<Array<CancellationReasonTranslationUpsertWithWhereUniqueWithoutCancellationReasonInput>>;
};

export type CancellationReasonTranslationUncheckedUpdateManyWithoutTranslationsInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode?: InputMaybe<Scalars['String']>;
};

export type CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode?: InputMaybe<Scalars['String']>;
};

export type CancellationReasonTranslationUpdateManyWithWhereWithoutCancellationReasonInput = {
  data: CancellationReasonTranslationUncheckedUpdateManyWithoutTranslationsInput;
  where: CancellationReasonTranslationScalarWhereInput;
};

export type CancellationReasonTranslationUpdateWithWhereUniqueWithoutCancellationReasonInput = {
  data: CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationUpsertWithWhereUniqueWithoutCancellationReasonInput = {
  create: CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput;
  update: CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  baseId?: InputMaybe<IntFilter>;
  cancellationReason?: InputMaybe<CancellationReasonRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  localeCode?: InputMaybe<StringFilter>;
};

export type CancellationReasonTranslationWhereUniqueInput = {
  baseId_localeCode?: InputMaybe<CancellationReasonTranslationBaseIdLocaleCodeCompoundUniqueInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CancellationReasonUncheckedCreateInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  for: OrderActor;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  translations?: InputMaybe<CancellationReasonTranslationUncheckedCreateNestedManyWithoutCancellationReasonInput>;
};

export type CancellationReasonUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<OrderActor>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  translations?: InputMaybe<CancellationReasonTranslationUncheckedUpdateManyWithoutCancellationReasonNestedInput>;
};

export type CancellationReasonUncheckedUpdateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<OrderActor>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
};

export type CancellationReasonWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  for?: InputMaybe<EnumOrderActorFilter>;
  id?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  translations?: InputMaybe<CancellationReasonTranslationListRelationFilter>;
};

export type CancellationReasonWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Country = {
  __typename?: 'Country';
  _count?: Maybe<CountryCount>;
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  states: Array<State>;
  status: Status;
};


export type CountryStatesArgs = {
  cursor?: InputMaybe<StateWhereUniqueInput>;
  distinct?: InputMaybe<Array<StateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};

export type CountryAvgAggregate = {
  __typename?: 'CountryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CountryAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CountryCount = {
  __typename?: 'CountryCount';
  states: Scalars['Int'];
};

export type CountryCountAggregate = {
  __typename?: 'CountryCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CountryCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CountryCreateManyInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type CountryGroupBy = {
  __typename?: 'CountryGroupBy';
  _avg?: Maybe<CountryAvgAggregate>;
  _count?: Maybe<CountryCountAggregate>;
  _max?: Maybe<CountryMaxAggregate>;
  _min?: Maybe<CountryMinAggregate>;
  _sum?: Maybe<CountrySumAggregate>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type CountryMaxAggregate = {
  __typename?: 'CountryMaxAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CountryMinAggregate = {
  __typename?: 'CountryMinAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CountryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CountryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CountryCountOrderByAggregateInput>;
  _max?: InputMaybe<CountryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CountryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CountrySumOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CountryOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  states?: InputMaybe<StateOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
};

export type CountryRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export enum CountryScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CountryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type CountrySumAggregate = {
  __typename?: 'CountrySumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type CountrySumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CountryUncheckedCreateInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  states?: InputMaybe<StateUncheckedCreateNestedManyWithoutCountryInput>;
  status?: InputMaybe<Status>;
};

export type CountryUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<StateUncheckedUpdateManyWithoutCountryNestedInput>;
  status?: InputMaybe<Status>;
};

export type CountryUncheckedUpdateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  states?: InputMaybe<StateListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type CountryWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum CouponStatus {
  Active = 'Active',
  Expired = 'Expired',
  FullyUsed = 'FullyUsed',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled'
}

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kid = 'KID',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

export type Customer = {
  __typename?: 'Customer';
  _count?: Maybe<CustomerCount>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emergencyContacts: Array<CustomerEmergencyContact>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  mobileNumber: Scalars['String'];
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  profilePicture?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  status: CustomerStatus;
  totalSpent: Money;
  updatedAt: Scalars['DateTime'];
  vehicles: Array<CustomerVehicle>;
  wallets: Array<Wallet>;
};


export type CustomerEmergencyContactsArgs = {
  cursor?: InputMaybe<CustomerEmergencyContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerEmergencyContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerEmergencyContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerEmergencyContactWhereInput>;
};


export type CustomerOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type CustomerReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type CustomerVehiclesArgs = {
  cursor?: InputMaybe<CustomerVehicleWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerVehicleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerVehicleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerVehicleWhereInput>;
};


export type CustomerWalletsArgs = {
  cursor?: InputMaybe<WalletWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletWhereInput>;
};

export type CustomerAccountUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
};

export type CustomerCount = {
  __typename?: 'CustomerCount';
  emergencyContacts: Scalars['Int'];
  orders: Scalars['Int'];
  reviews: Scalars['Int'];
  vehicles: Scalars['Int'];
  wallets: Scalars['Int'];
};

export type CustomerCreateNestedOneWithoutWalletsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutWalletsInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutWalletsInput>;
};

export type CustomerCreateOrConnectWithoutUserInput = {
  create: CustomerUncheckedCreateWithoutUserInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutWalletsInput = {
  create: CustomerUncheckedCreateWithoutWalletsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerEmergencyContact = {
  __typename?: 'CustomerEmergencyContact';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CustomerEmergencyContactOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  relationship?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CustomerEmergencyContactScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  Relationship = 'relationship',
  UpdatedAt = 'updatedAt'
}

export type CustomerEmergencyContactUncheckedCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship?: InputMaybe<Scalars['String']>;
};

export type CustomerEmergencyContactUncheckedUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type CustomerEmergencyContactWhereInput = {
  AND?: InputMaybe<Array<CustomerEmergencyContactWhereInput>>;
  NOT?: InputMaybe<Array<CustomerEmergencyContactWhereInput>>;
  OR?: InputMaybe<Array<CustomerEmergencyContactWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerEmergencyContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  ordersCount?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  wallets?: InputMaybe<WalletOrderByRelationAggregateInput>;
};

export type CustomerRegisterInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  mobileNumber: Scalars['String'];
  password: Scalars['String'];
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export type CustomerSocialRegisterInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  provider: Scalars['String'];
  token: Scalars['String'];
};

export enum CustomerStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Deleted = 'Deleted'
}

export type CustomerUncheckedCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutUserInput>;
};

export type CustomerUncheckedCreateWithoutUserInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutCustomerInput>;
};

export type CustomerUncheckedCreateWithoutWalletsInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
};

export type CustomerUncheckedUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  wallets?: InputMaybe<WalletUncheckedUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerVehicle = {
  __typename?: 'CustomerVehicle';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  licensePlate: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year?: Maybe<Scalars['Int']>;
};

export type CustomerVehicleCustomerIdLicensePlateCompoundUniqueInput = {
  customerId: Scalars['String'];
  licensePlate: Scalars['String'];
};

export type CustomerVehicleOrderByWithRelationInput = {
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  licensePlate?: InputMaybe<SortOrder>;
  make?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export enum CustomerVehicleScalarFieldEnum {
  Color = 'color',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  IsDefault = 'isDefault',
  LicensePlate = 'licensePlate',
  Make = 'make',
  Model = 'model',
  UpdatedAt = 'updatedAt',
  Year = 'year'
}

export type CustomerVehicleUncheckedCreateInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  licensePlate: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  year?: InputMaybe<Scalars['Int']>;
};

export type CustomerVehicleUncheckedUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type CustomerVehicleWhereInput = {
  AND?: InputMaybe<Array<CustomerVehicleWhereInput>>;
  NOT?: InputMaybe<Array<CustomerVehicleWhereInput>>;
  OR?: InputMaybe<Array<CustomerVehicleWhereInput>>;
  color?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  licensePlate?: InputMaybe<StringFilter>;
  make?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type CustomerVehicleWhereUniqueInput = {
  customerId_licensePlate?: InputMaybe<CustomerVehicleCustomerIdLicensePlateCompoundUniqueInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumCustomerStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  wallets?: InputMaybe<WalletListRelationFilter>;
};

export type CustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type DashboardReport = {
  __typename?: 'DashboardReport';
  totalNewCustomer: Scalars['Int'];
  totalOrder: Scalars['Int'];
  totalRevenue: Scalars['Decimal'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum DayOfWeek {
  AllDays = 'AllDays',
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Weekday = 'Weekday',
  Weekend = 'Weekend'
}

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DiscountCoupon = {
  __typename?: 'DiscountCoupon';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  discountType: DiscountType;
  discountValue: Scalars['Decimal'];
  endsAt?: Maybe<Scalars['DateTime']>;
  formattedDiscountValue: Scalars['String'];
  formattedScheduledTime: Scalars['String'];
  id: Scalars['ID'];
  maxDiscountAmount?: Maybe<Scalars['Decimal']>;
  maxUses?: Maybe<Scalars['Int']>;
  maxUsesPerUser?: Maybe<Scalars['Int']>;
  minOrderAmount?: Maybe<Scalars['Decimal']>;
  name: Scalars['String'];
  startsAt?: Maybe<Scalars['DateTime']>;
  status: CouponStatus;
  totalUses: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type DiscountCouponFormattedScheduledTimeArgs = {
  timezone?: InputMaybe<Scalars['String']>;
};

export type DiscountCouponOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  discountValue?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxDiscountAmount?: InputMaybe<SortOrder>;
  maxUses?: InputMaybe<SortOrder>;
  maxUsesPerUser?: InputMaybe<SortOrder>;
  minOrderAmount?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalUses?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DiscountCouponUncheckedCreateInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  discountType: DiscountType;
  discountValue: Scalars['Decimal'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  maxDiscountAmount?: InputMaybe<Scalars['Decimal']>;
  maxUses?: InputMaybe<Scalars['Int']>;
  maxUsesPerUser?: InputMaybe<Scalars['Int']>;
  minOrderAmount?: InputMaybe<Scalars['Decimal']>;
  name: Scalars['String'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<CouponStatus>;
};

export type DiscountCouponUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<DiscountType>;
  discountValue?: InputMaybe<Scalars['Decimal']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  maxDiscountAmount?: InputMaybe<Scalars['Decimal']>;
  maxUses?: InputMaybe<Scalars['Int']>;
  maxUsesPerUser?: InputMaybe<Scalars['Int']>;
  minOrderAmount?: InputMaybe<Scalars['Decimal']>;
  name?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<CouponStatus>;
};

export type DiscountCouponWhereInput = {
  AND?: InputMaybe<Array<DiscountCouponWhereInput>>;
  NOT?: InputMaybe<Array<DiscountCouponWhereInput>>;
  OR?: InputMaybe<Array<DiscountCouponWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountType?: InputMaybe<EnumDiscountTypeFilter>;
  discountValue?: InputMaybe<DecimalFilter>;
  endsAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  maxDiscountAmount?: InputMaybe<DecimalNullableFilter>;
  maxUses?: InputMaybe<IntNullableFilter>;
  maxUsesPerUser?: InputMaybe<IntNullableFilter>;
  minOrderAmount?: InputMaybe<DecimalNullableFilter>;
  name?: InputMaybe<StringFilter>;
  startsAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumCouponStatusFilter>;
  totalUses?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DiscountCouponWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum DiscountType {
  FixedAmount = 'FixedAmount',
  Percentage = 'Percentage'
}

export type Distance = {
  __typename?: 'Distance';
  text: Scalars['String'];
  value: Scalars['Int'];
};

export type DistanceMatrix = {
  __typename?: 'DistanceMatrix';
  distance: Distance;
  duration: Duration;
};

export type DrivingLicense = {
  __typename?: 'DrivingLicense';
  attachments?: Maybe<Array<GenericFile>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
};

export type Duration = {
  __typename?: 'Duration';
  text: Scalars['String'];
  value: Scalars['Int'];
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type EnumAdjustmentTypeFilter = {
  equals?: InputMaybe<AdjustmentType>;
  in?: InputMaybe<Array<AdjustmentType>>;
  not?: InputMaybe<NestedEnumAdjustmentTypeFilter>;
  notIn?: InputMaybe<Array<AdjustmentType>>;
};

export type EnumBalanceTypeFilter = {
  equals?: InputMaybe<BalanceType>;
  in?: InputMaybe<Array<BalanceType>>;
  not?: InputMaybe<NestedEnumBalanceTypeFilter>;
  notIn?: InputMaybe<Array<BalanceType>>;
};

export type EnumCouponStatusFilter = {
  equals?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  not?: InputMaybe<NestedEnumCouponStatusFilter>;
  notIn?: InputMaybe<Array<CouponStatus>>;
};

export type EnumCustomerStatusFilter = {
  equals?: InputMaybe<CustomerStatus>;
  in?: InputMaybe<Array<CustomerStatus>>;
  not?: InputMaybe<NestedEnumCustomerStatusFilter>;
  notIn?: InputMaybe<Array<CustomerStatus>>;
};

export type EnumDiscountTypeFilter = {
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type EnumOrderActorFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderActorNullableFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorNullableFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderActorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderActorFilter>;
  _min?: InputMaybe<NestedEnumOrderActorFilter>;
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderStateFilter = {
  equals?: InputMaybe<OrderState>;
  in?: InputMaybe<Array<OrderState>>;
  not?: InputMaybe<NestedEnumOrderStateFilter>;
  notIn?: InputMaybe<Array<OrderState>>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumOrderTypeFilter = {
  equals?: InputMaybe<OrderType>;
  in?: InputMaybe<Array<OrderType>>;
  not?: InputMaybe<NestedEnumOrderTypeFilter>;
  notIn?: InputMaybe<Array<OrderType>>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentProcessorFilter = {
  equals?: InputMaybe<PaymentProcessor>;
  in?: InputMaybe<Array<PaymentProcessor>>;
  not?: InputMaybe<NestedEnumPaymentProcessorFilter>;
  notIn?: InputMaybe<Array<PaymentProcessor>>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumReviewTypeFilter = {
  equals?: InputMaybe<ReviewType>;
  in?: InputMaybe<Array<ReviewType>>;
  not?: InputMaybe<NestedEnumReviewTypeFilter>;
  notIn?: InputMaybe<Array<ReviewType>>;
};

export type EnumServiceProviderStatusFilter = {
  equals?: InputMaybe<ServiceProviderStatus>;
  in?: InputMaybe<Array<ServiceProviderStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderStatus>>;
};

export type EnumServiceProviderTypeFilter = {
  equals?: InputMaybe<ServiceProviderType>;
  in?: InputMaybe<Array<ServiceProviderType>>;
  not?: InputMaybe<NestedEnumServiceProviderTypeFilter>;
  notIn?: InputMaybe<Array<ServiceProviderType>>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumSystemSettingGroupFilter = {
  equals?: InputMaybe<SystemSettingGroup>;
  in?: InputMaybe<Array<SystemSettingGroup>>;
  not?: InputMaybe<NestedEnumSystemSettingGroupFilter>;
  notIn?: InputMaybe<Array<SystemSettingGroup>>;
};

export type EnumTopupMethodFilter = {
  equals?: InputMaybe<TopupMethod>;
  in?: InputMaybe<Array<TopupMethod>>;
  not?: InputMaybe<NestedEnumTopupMethodFilter>;
  notIn?: InputMaybe<Array<TopupMethod>>;
};

export type EnumTopupStatusFilter = {
  equals?: InputMaybe<TopupStatus>;
  in?: InputMaybe<Array<TopupStatus>>;
  not?: InputMaybe<NestedEnumTopupStatusFilter>;
  notIn?: InputMaybe<Array<TopupStatus>>;
};

export type EnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type EnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumZoneTypeFilter = {
  equals?: InputMaybe<ZoneType>;
  in?: InputMaybe<Array<ZoneType>>;
  not?: InputMaybe<NestedEnumZoneTypeFilter>;
  notIn?: InputMaybe<Array<ZoneType>>;
};

export type EstimatedOrder = {
  __typename?: 'EstimatedOrder';
  baseDistance: Scalars['Decimal'];
  couponCode?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Money>;
  distances: Array<Scalars['Decimal']>;
  overDistance: Scalars['Decimal'];
  subTotalAmount: Money;
  subTotalBreakdown: OrderSubTotalBreakdown;
  totalAmount: Money;
  totalDistance: Scalars['Decimal'];
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type GeneratePresignedFileInput = {
  fileName: Scalars['String'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
};

export type GenericFile = {
  __typename?: 'GenericFile';
  /** A word or phrase to describe the contents or the function of a file */
  alt?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  /** The generic file's size in bytes */
  size?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type HelpCenter = {
  __typename?: 'HelpCenter';
  url: Scalars['String'];
};

export type HttpHeader = {
  __typename?: 'HttpHeader';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type HttpRequestParameter = {
  __typename?: 'HttpRequestParameter';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type IdTokenPayload = {
  __typename?: 'IdTokenPayload';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
};

export type IdentityDocument = {
  __typename?: 'IdentityDocument';
  attachments?: Maybe<Array<GenericFile>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  identityNumber?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

/** Available locales */
export enum LocaleCode {
  En = 'EN',
  Ms = 'MS',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW'
}

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Metafield = {
  __typename?: 'Metafield';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal'];
  currencyCode: CurrencyCode;
  formattedAmount: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adjustWalletBalance: WalletBalanceAdjustment;
  adminLogin: Auth;
  adminResetPassword: StatusResponse;
  assignOrder?: Maybe<Order>;
  cancelOrder?: Maybe<Order>;
  createManyCancellationReason: AffectedRowsOutput;
  createManyCountry: AffectedRowsOutput;
  createManyState: AffectedRowsOutput;
  createManyVehicleMake: AffectedRowsOutput;
  createOneAdminRole: AdminRole;
  createOneAdminUser: AdminUser;
  createOneCancellationReason: CancellationReason;
  createOneCountry: Country;
  createOneCustomerEmergencyContact: CustomerEmergencyContact;
  createOneCustomerVehicle: CustomerVehicle;
  createOneDiscountCoupon: DiscountCoupon;
  createOneReview: Review;
  createOneService: Service;
  createOneServiceProvider: ServiceProvider;
  createOneState: State;
  createOneTieredPrice: TieredPrice;
  createOneVehicleMake: VehicleMake;
  createOneZone: Zone;
  deleteCustomerAccount: Customer;
  deleteManyCancellationReason: AffectedRowsOutput;
  deleteManyCountry: AffectedRowsOutput;
  deleteManyState: AffectedRowsOutput;
  deleteManyVehicleMake: AffectedRowsOutput;
  deleteOneAdminRole: AdminRole;
  deleteOneAdminUser?: Maybe<AdminUser>;
  deleteOneCancellationReason?: Maybe<CancellationReason>;
  deleteOneCountry?: Maybe<Country>;
  deleteOneCustomerEmergencyContact?: Maybe<CustomerEmergencyContact>;
  deleteOneCustomerVehicle?: Maybe<CustomerVehicle>;
  deleteOneDiscountCoupon?: Maybe<DiscountCoupon>;
  deleteOneService?: Maybe<Service>;
  deleteOneServiceProvider?: Maybe<ServiceProvider>;
  deleteOneState?: Maybe<State>;
  deleteOneTieredPrice?: Maybe<TieredPrice>;
  deleteOneVehicleMake?: Maybe<VehicleMake>;
  deleteOneZone?: Maybe<Zone>;
  generatePresignedFile: SignedFile;
  login: Auth;
  placeOrder: Order;
  refreshToken: Auth;
  register: Auth;
  requestOtp: OtpChallenge;
  resetPassword: StatusResponse;
  serviceProviderLogin: Auth;
  serviceProviderResetPassword: StatusResponse;
  setEstimatedArrivalTime?: Maybe<Order>;
  socialLogin: Auth;
  socialRegister: Auth;
  topUpWallet: TopUpWalletRequest;
  transitionOrder?: Maybe<Order>;
  updateCustomerAccount: Customer;
  updateManyCancellationReason: AffectedRowsOutput;
  updateManyCountry: AffectedRowsOutput;
  updateManyState: AffectedRowsOutput;
  updateManyVehicleMake: AffectedRowsOutput;
  updateOneAdminRole?: Maybe<AdminRole>;
  updateOneAdminUser?: Maybe<AdminUser>;
  updateOneCancellationReason?: Maybe<CancellationReason>;
  updateOneCountry?: Maybe<Country>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneCustomerEmergencyContact?: Maybe<CustomerEmergencyContact>;
  updateOneCustomerVehicle?: Maybe<CustomerVehicle>;
  updateOneDiscountCoupon?: Maybe<DiscountCoupon>;
  updateOneService?: Maybe<Service>;
  updateOneServiceProvider?: Maybe<ServiceProvider>;
  updateOneServiceRate?: Maybe<ServiceRate>;
  updateOneState?: Maybe<State>;
  updateOneSystemSetting?: Maybe<SystemSetting>;
  updateOneTieredPrice?: Maybe<TieredPrice>;
  updateOneVehicleMake?: Maybe<VehicleMake>;
  updateOneZone?: Maybe<Zone>;
  upsertOneCancellationReason: CancellationReason;
  upsertOneCountry: Country;
  upsertOneState: State;
  upsertOneVehicleMake: VehicleMake;
  verifyOtp: StatusResponse;
};


export type MutationAdjustWalletBalanceArgs = {
  data: AdjustWalletBalanceInput;
};


export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};


export type MutationAdminResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationAssignOrderArgs = {
  id: Scalars['ID'];
  serviceProviderId: Scalars['String'];
};


export type MutationCancelOrderArgs = {
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reasonCode?: InputMaybe<Scalars['String']>;
};


export type MutationCreateManyCancellationReasonArgs = {
  data: Array<CancellationReasonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCountryArgs = {
  data: Array<CountryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStateArgs = {
  data: Array<StateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyVehicleMakeArgs = {
  data: Array<VehicleMakeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneAdminRoleArgs = {
  data: AdminRoleCreateInputV2;
};


export type MutationCreateOneAdminUserArgs = {
  data: AdminUserCreateInputV2;
};


export type MutationCreateOneCancellationReasonArgs = {
  data: CancellationReasonUncheckedCreateInput;
};


export type MutationCreateOneCountryArgs = {
  data: CountryUncheckedCreateInput;
};


export type MutationCreateOneCustomerEmergencyContactArgs = {
  data: CustomerEmergencyContactUncheckedCreateInput;
};


export type MutationCreateOneCustomerVehicleArgs = {
  data: CustomerVehicleUncheckedCreateInput;
};


export type MutationCreateOneDiscountCouponArgs = {
  data: DiscountCouponUncheckedCreateInput;
};


export type MutationCreateOneReviewArgs = {
  data: ReviewUncheckedCreateInput;
};


export type MutationCreateOneServiceArgs = {
  data: ServiceUncheckedCreateInput;
};


export type MutationCreateOneServiceProviderArgs = {
  data: ServiceProviderCreateInputV2;
};


export type MutationCreateOneStateArgs = {
  data: StateUncheckedCreateInput;
};


export type MutationCreateOneTieredPriceArgs = {
  data: TieredPriceCreateInputV2;
};


export type MutationCreateOneVehicleMakeArgs = {
  data: VehicleMakeUncheckedCreateInput;
};


export type MutationCreateOneZoneArgs = {
  data: ZoneUncheckedCreateInput;
};


export type MutationDeleteManyCancellationReasonArgs = {
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type MutationDeleteManyCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};


export type MutationDeleteManyStateArgs = {
  where?: InputMaybe<StateWhereInput>;
};


export type MutationDeleteManyVehicleMakeArgs = {
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type MutationDeleteOneAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
};


export type MutationDeleteOneAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type MutationDeleteOneCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type MutationDeleteOneCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type MutationDeleteOneCustomerEmergencyContactArgs = {
  where: CustomerEmergencyContactWhereUniqueInput;
};


export type MutationDeleteOneCustomerVehicleArgs = {
  where: CustomerVehicleWhereUniqueInput;
};


export type MutationDeleteOneDiscountCouponArgs = {
  where: DiscountCouponWhereUniqueInput;
};


export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteOneServiceProviderArgs = {
  where: ServiceProviderWhereUniqueInput;
};


export type MutationDeleteOneStateArgs = {
  where: StateWhereUniqueInput;
};


export type MutationDeleteOneTieredPriceArgs = {
  where: TieredPriceWhereUniqueInput;
};


export type MutationDeleteOneVehicleMakeArgs = {
  where: VehicleMakeWhereUniqueInput;
};


export type MutationDeleteOneZoneArgs = {
  where: ZoneWhereUniqueInput;
};


export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationPlaceOrderArgs = {
  data: PlaceOrderInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRegisterArgs = {
  data: CustomerRegisterInput;
};


export type MutationRequestOtpArgs = {
  data: RequestOtpInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationServiceProviderLoginArgs = {
  data: ServiceProviderLoginInput;
};


export type MutationServiceProviderResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSetEstimatedArrivalTimeArgs = {
  estimatedArrivalTime: Scalars['DateTime'];
  orderId: Scalars['String'];
};


export type MutationSocialLoginArgs = {
  data: SocialLoginInput;
};


export type MutationSocialRegisterArgs = {
  data: CustomerSocialRegisterInput;
};


export type MutationTopUpWalletArgs = {
  data: TopUpWalletInput;
};


export type MutationTransitionOrderArgs = {
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  status: OrderTransitionStatus;
};


export type MutationUpdateCustomerAccountArgs = {
  data: CustomerAccountUpdateInput;
};


export type MutationUpdateManyCancellationReasonArgs = {
  data: CancellationReasonUncheckedUpdateManyInput;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type MutationUpdateManyCountryArgs = {
  data: CountryUncheckedUpdateManyInput;
  where?: InputMaybe<CountryWhereInput>;
};


export type MutationUpdateManyStateArgs = {
  data: StateUncheckedUpdateManyInput;
  where?: InputMaybe<StateWhereInput>;
};


export type MutationUpdateManyVehicleMakeArgs = {
  data: VehicleMakeUncheckedUpdateManyInput;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type MutationUpdateOneAdminRoleArgs = {
  data: AdminRoleUpdateInputV2;
  where: AdminRoleWhereUniqueInput;
};


export type MutationUpdateOneAdminUserArgs = {
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
};


export type MutationUpdateOneCancellationReasonArgs = {
  data: CancellationReasonUncheckedUpdateInput;
  where: CancellationReasonWhereUniqueInput;
};


export type MutationUpdateOneCountryArgs = {
  data: CountryUncheckedUpdateInput;
  where: CountryWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUncheckedUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneCustomerEmergencyContactArgs = {
  data: CustomerEmergencyContactUncheckedUpdateInput;
  where: CustomerEmergencyContactWhereUniqueInput;
};


export type MutationUpdateOneCustomerVehicleArgs = {
  data: CustomerVehicleUncheckedUpdateInput;
  where: CustomerVehicleWhereUniqueInput;
};


export type MutationUpdateOneDiscountCouponArgs = {
  data: DiscountCouponUncheckedUpdateInput;
  where: DiscountCouponWhereUniqueInput;
};


export type MutationUpdateOneServiceArgs = {
  data: ServiceUncheckedUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpdateOneServiceProviderArgs = {
  data: ServiceProviderUncheckedUpdateInput;
  where: ServiceProviderWhereUniqueInput;
};


export type MutationUpdateOneServiceRateArgs = {
  data: ServiceRateUpdateInputV2;
};


export type MutationUpdateOneStateArgs = {
  data: StateUncheckedUpdateInput;
  where: StateWhereUniqueInput;
};


export type MutationUpdateOneSystemSettingArgs = {
  data: SystemSettingUncheckedUpdateInput;
  where: SystemSettingWhereUniqueInput;
};


export type MutationUpdateOneTieredPriceArgs = {
  data: TieredPriceUpdateInputV2;
  where: TieredPriceWhereUniqueInput;
};


export type MutationUpdateOneVehicleMakeArgs = {
  data: VehicleMakeUncheckedUpdateInput;
  where: VehicleMakeWhereUniqueInput;
};


export type MutationUpdateOneZoneArgs = {
  data: ZoneUncheckedUpdateInput;
  where: ZoneWhereUniqueInput;
};


export type MutationUpsertOneCancellationReasonArgs = {
  create: CancellationReasonUncheckedCreateInput;
  update: CancellationReasonUncheckedUpdateInput;
  where: CancellationReasonWhereUniqueInput;
};


export type MutationUpsertOneCountryArgs = {
  create: CountryUncheckedCreateInput;
  update: CountryUncheckedUpdateInput;
  where: CountryWhereUniqueInput;
};


export type MutationUpsertOneStateArgs = {
  create: StateUncheckedCreateInput;
  update: StateUncheckedUpdateInput;
  where: StateWhereUniqueInput;
};


export type MutationUpsertOneVehicleMakeArgs = {
  create: VehicleMakeUncheckedCreateInput;
  update: VehicleMakeUncheckedUpdateInput;
  where: VehicleMakeWhereUniqueInput;
};


export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAdjustmentTypeFilter = {
  equals?: InputMaybe<AdjustmentType>;
  in?: InputMaybe<Array<AdjustmentType>>;
  not?: InputMaybe<NestedEnumAdjustmentTypeFilter>;
  notIn?: InputMaybe<Array<AdjustmentType>>;
};

export type NestedEnumBalanceTypeFilter = {
  equals?: InputMaybe<BalanceType>;
  in?: InputMaybe<Array<BalanceType>>;
  not?: InputMaybe<NestedEnumBalanceTypeFilter>;
  notIn?: InputMaybe<Array<BalanceType>>;
};

export type NestedEnumCouponStatusFilter = {
  equals?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  not?: InputMaybe<NestedEnumCouponStatusFilter>;
  notIn?: InputMaybe<Array<CouponStatus>>;
};

export type NestedEnumCustomerStatusFilter = {
  equals?: InputMaybe<CustomerStatus>;
  in?: InputMaybe<Array<CustomerStatus>>;
  not?: InputMaybe<NestedEnumCustomerStatusFilter>;
  notIn?: InputMaybe<Array<CustomerStatus>>;
};

export type NestedEnumDiscountTypeFilter = {
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type NestedEnumOrderActorFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderActorNullableFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorNullableFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderActorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderActorFilter>;
  _min?: InputMaybe<NestedEnumOrderActorFilter>;
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderStateFilter = {
  equals?: InputMaybe<OrderState>;
  in?: InputMaybe<Array<OrderState>>;
  not?: InputMaybe<NestedEnumOrderStateFilter>;
  notIn?: InputMaybe<Array<OrderState>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumOrderTypeFilter = {
  equals?: InputMaybe<OrderType>;
  in?: InputMaybe<Array<OrderType>>;
  not?: InputMaybe<NestedEnumOrderTypeFilter>;
  notIn?: InputMaybe<Array<OrderType>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentProcessorFilter = {
  equals?: InputMaybe<PaymentProcessor>;
  in?: InputMaybe<Array<PaymentProcessor>>;
  not?: InputMaybe<NestedEnumPaymentProcessorFilter>;
  notIn?: InputMaybe<Array<PaymentProcessor>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumReviewTypeFilter = {
  equals?: InputMaybe<ReviewType>;
  in?: InputMaybe<Array<ReviewType>>;
  not?: InputMaybe<NestedEnumReviewTypeFilter>;
  notIn?: InputMaybe<Array<ReviewType>>;
};

export type NestedEnumServiceProviderStatusFilter = {
  equals?: InputMaybe<ServiceProviderStatus>;
  in?: InputMaybe<Array<ServiceProviderStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderStatus>>;
};

export type NestedEnumServiceProviderTypeFilter = {
  equals?: InputMaybe<ServiceProviderType>;
  in?: InputMaybe<Array<ServiceProviderType>>;
  not?: InputMaybe<NestedEnumServiceProviderTypeFilter>;
  notIn?: InputMaybe<Array<ServiceProviderType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumSystemSettingGroupFilter = {
  equals?: InputMaybe<SystemSettingGroup>;
  in?: InputMaybe<Array<SystemSettingGroup>>;
  not?: InputMaybe<NestedEnumSystemSettingGroupFilter>;
  notIn?: InputMaybe<Array<SystemSettingGroup>>;
};

export type NestedEnumTopupMethodFilter = {
  equals?: InputMaybe<TopupMethod>;
  in?: InputMaybe<Array<TopupMethod>>;
  not?: InputMaybe<NestedEnumTopupMethodFilter>;
  notIn?: InputMaybe<Array<TopupMethod>>;
};

export type NestedEnumTopupStatusFilter = {
  equals?: InputMaybe<TopupStatus>;
  in?: InputMaybe<Array<TopupStatus>>;
  not?: InputMaybe<NestedEnumTopupStatusFilter>;
  notIn?: InputMaybe<Array<TopupStatus>>;
};

export type NestedEnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type NestedEnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumZoneTypeFilter = {
  equals?: InputMaybe<ZoneType>;
  in?: InputMaybe<Array<ZoneType>>;
  not?: InputMaybe<NestedEnumZoneTypeFilter>;
  notIn?: InputMaybe<Array<ZoneType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  cancelComments?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelledBy?: Maybe<OrderActor>;
  completedAt?: Maybe<Scalars['DateTime']>;
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  customerNotes?: Maybe<Scalars['String']>;
  customerReview?: Maybe<Review>;
  destination: Waypoint;
  discountAmount?: Maybe<Money>;
  distanceMatrix?: Maybe<DistanceMatrix>;
  /**
   *
   * @TypeGraphQL.omit(output: true)
   */
  distances?: Maybe<Scalars['JSON']>;
  events: Array<OrderEvent>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  formattedScheduledPickupTime: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCancelable: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  isPickedUp: Scalars['Boolean'];
  isRateable: Scalars['Boolean'];
  midStops?: Maybe<Array<Waypoint>>;
  orderNumber?: Maybe<Scalars['String']>;
  origin: Waypoint;
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatus;
  payments: Array<Payment>;
  reviews: Array<Review>;
  scheduledPickupAt?: Maybe<Scalars['DateTime']>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars['String']>;
  status: OrderStatus;
  statusDescription: Scalars['String'];
  statusDisplay: Scalars['String'];
  subTotalAmount: Money;
  subTotalBreakdown: OrderSubTotalBreakdown;
  totalAmount: Money;
  totalDistance: Scalars['Decimal'];
  type: OrderType;
  updatedAt: Scalars['DateTime'];
  vehicleId?: Maybe<Scalars['String']>;
  vehicleSnapshot: VehicleSnapshot;
  wayPoints?: Maybe<Array<Waypoint>>;
};


export type OrderEventsArgs = {
  cursor?: InputMaybe<OrderEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderEventWhereInput>;
};


export type OrderFormattedScheduledPickupTimeArgs = {
  timezone?: InputMaybe<Scalars['String']>;
};


export type OrderPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type OrderReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};

export enum OrderActor {
  Customer = 'Customer',
  Operator = 'Operator',
  ServiceProvider = 'ServiceProvider'
}

export type OrderCount = {
  __typename?: 'OrderCount';
  events: Scalars['Int'];
  payments: Scalars['Int'];
  reviews: Scalars['Int'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['String'];
  payload: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  eventType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OrderEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  Id = 'id',
  OrderId = 'orderId',
  Payload = 'payload',
  UpdatedAt = 'updatedAt'
}

export type OrderEventWhereInput = {
  AND?: InputMaybe<Array<OrderEventWhereInput>>;
  NOT?: InputMaybe<Array<OrderEventWhereInput>>;
  OR?: InputMaybe<Array<OrderEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  cancelComments?: InputMaybe<SortOrder>;
  cancelReason?: InputMaybe<SortOrder>;
  cancelledBy?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrder>;
  couponCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  customerNotes?: InputMaybe<SortOrder>;
  destination?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  distances?: InputMaybe<SortOrder>;
  failureCode?: InputMaybe<SortOrder>;
  failureMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  midStops?: InputMaybe<SortOrder>;
  orderNumber?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  scheduledPickupAt?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subTotalAmount?: InputMaybe<SortOrder>;
  subTotalBreakdown?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  totalDistance?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vehicleId?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  CancelComments = 'cancelComments',
  CancelReason = 'cancelReason',
  CancelledBy = 'cancelledBy',
  CompletedAt = 'completedAt',
  CouponCode = 'couponCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  CustomerId = 'customerId',
  CustomerNotes = 'customerNotes',
  Destination = 'destination',
  DiscountAmount = 'discountAmount',
  Distances = 'distances',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  Id = 'id',
  IsActive = 'isActive',
  Metadata = 'metadata',
  MidStops = 'midStops',
  OrderNumber = 'orderNumber',
  Origin = 'origin',
  PaymentMethod = 'paymentMethod',
  PaymentStatus = 'paymentStatus',
  ScheduledPickupAt = 'scheduledPickupAt',
  ServiceId = 'serviceId',
  ServiceProviderId = 'serviceProviderId',
  State = 'state',
  Status = 'status',
  SubTotalAmount = 'subTotalAmount',
  SubTotalBreakdown = 'subTotalBreakdown',
  TotalAmount = 'totalAmount',
  TotalDistance = 'totalDistance',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  VehicleId = 'vehicleId',
  VehicleSnapshot = 'vehicleSnapshot',
  ZoneId = 'zoneId'
}

export enum OrderState {
  Arrived = 'Arrived',
  Assigned = 'Assigned',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Created = 'Created',
  Enroute = 'Enroute',
  Failed = 'Failed',
  PickedUp = 'PickedUp'
}

export enum OrderStatus {
  Allocating = 'Allocating',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  Ongoing = 'Ongoing'
}

export type OrderSubTotalBreakdown = {
  __typename?: 'OrderSubTotalBreakdown';
  baseFare: Money;
  distanceFare: Money;
  durationFare?: Maybe<Money>;
  waitingFee?: Maybe<Money>;
};

export enum OrderTransitionStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  PickedUp = 'PickedUp'
}

export enum OrderType {
  Instant = 'Instant',
  Scheduled = 'Scheduled'
}

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  cancelComments?: InputMaybe<StringNullableFilter>;
  cancelReason?: InputMaybe<StringNullableFilter>;
  cancelledBy?: InputMaybe<EnumOrderActorNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  couponCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  customerNotes?: InputMaybe<StringNullableFilter>;
  destination?: InputMaybe<JsonFilter>;
  discountAmount?: InputMaybe<DecimalNullableFilter>;
  distances?: InputMaybe<JsonNullableFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  midStops?: InputMaybe<JsonNullableFilter>;
  orderNumber?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<JsonFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  scheduledPickupAt?: InputMaybe<DateTimeNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumOrderStateFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  subTotalAmount?: InputMaybe<DecimalFilter>;
  subTotalBreakdown?: InputMaybe<JsonNullableFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  totalDistance?: InputMaybe<DecimalFilter>;
  type?: InputMaybe<EnumOrderTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringNullableFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  orderNumber?: InputMaybe<Scalars['String']>;
};

export type OtpChallenge = {
  __typename?: 'OtpChallenge';
  consumer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiredOn: Scalars['DateTime'];
  id: Scalars['ID'];
  method: OtpMethod;
  purpose: OtpPurpose;
  retryWaitSeconds: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum OtpMethod {
  Email = 'Email',
  Sms = 'SMS'
}

export enum OtpPurpose {
  PasswordReset = 'PasswordReset',
  Registration = 'Registration'
}

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedAdminPermission = {
  __typename?: 'PaginatedAdminPermission';
  items?: Maybe<Array<AdminPermission>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminRole = {
  __typename?: 'PaginatedAdminRole';
  items?: Maybe<Array<AdminRole>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  items?: Maybe<Array<AdminUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCustomer = {
  __typename?: 'PaginatedCustomer';
  items?: Maybe<Array<Customer>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCustomerEmergencyContact = {
  __typename?: 'PaginatedCustomerEmergencyContact';
  items?: Maybe<Array<CustomerEmergencyContact>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCustomerVehicle = {
  __typename?: 'PaginatedCustomerVehicle';
  items?: Maybe<Array<CustomerVehicle>>;
  metadata: PaginatationMetadata;
};

export type PaginatedDiscountCoupon = {
  __typename?: 'PaginatedDiscountCoupon';
  items?: Maybe<Array<DiscountCoupon>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOrder = {
  __typename?: 'PaginatedOrder';
  items?: Maybe<Array<Order>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOrderEvent = {
  __typename?: 'PaginatedOrderEvent';
  items?: Maybe<Array<OrderEvent>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment';
  items?: Maybe<Array<Payment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedReview = {
  __typename?: 'PaginatedReview';
  items?: Maybe<Array<Review>>;
  metadata: PaginatationMetadata;
};

export type PaginatedService = {
  __typename?: 'PaginatedService';
  items?: Maybe<Array<Service>>;
  metadata: PaginatationMetadata;
};

export type PaginatedServiceProvider = {
  __typename?: 'PaginatedServiceProvider';
  items?: Maybe<Array<ServiceProvider>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSystemSetting = {
  __typename?: 'PaginatedSystemSetting';
  items?: Maybe<Array<SystemSetting>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTieredPrice = {
  __typename?: 'PaginatedTieredPrice';
  items?: Maybe<Array<TieredPrice>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWallet = {
  __typename?: 'PaginatedWallet';
  items?: Maybe<Array<Wallet>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWalletBalanceAdjustment = {
  __typename?: 'PaginatedWalletBalanceAdjustment';
  items?: Maybe<Array<WalletBalanceAdjustment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedZone = {
  __typename?: 'PaginatedZone';
  items?: Maybe<Array<Zone>>;
  metadata: PaginatationMetadata;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Money;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  gatewayRequest?: Maybe<Scalars['JSON']>;
  gatewayResponse?: Maybe<Scalars['JSON']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  processor: PaymentProcessor;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<Scalars['String']>;
  requeryResponse?: Maybe<Scalars['JSON']>;
  status: PaymentStatus;
  topup?: Maybe<Topup>;
  topupId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentListRelationFilter = {
  every?: InputMaybe<PaymentWhereInput>;
  none?: InputMaybe<PaymentWhereInput>;
  some?: InputMaybe<PaymentWhereInput>;
};

export enum PaymentMethod {
  Cash = 'Cash',
  Wallet = 'Wallet'
}

export type PaymentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  failureCode?: InputMaybe<SortOrder>;
  failureMessage?: InputMaybe<SortOrder>;
  gatewayRequest?: InputMaybe<SortOrder>;
  gatewayResponse?: InputMaybe<SortOrder>;
  gatewayTransactionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  processor?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  referenceType?: InputMaybe<SortOrder>;
  requeryResponse?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  topup?: InputMaybe<TopupOrderByWithRelationInput>;
  topupId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PaymentProcessor {
  Cash = 'Cash',
  IPay88 = 'IPay88',
  Wallet = 'Wallet'
}

export enum PaymentScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Description = 'description',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  GatewayRequest = 'gatewayRequest',
  GatewayResponse = 'gatewayResponse',
  GatewayTransactionId = 'gatewayTransactionId',
  Id = 'id',
  Metadata = 'metadata',
  OrderId = 'orderId',
  Processor = 'processor',
  ReferenceId = 'referenceId',
  ReferenceType = 'referenceType',
  RequeryResponse = 'requeryResponse',
  Status = 'status',
  TopupId = 'topupId',
  UpdatedAt = 'updatedAt'
}

export enum PaymentStatus {
  Authorized = 'Authorized',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Pending = 'Pending',
  Refunded = 'Refunded',
  RequiresAction = 'RequiresAction',
  Succeeded = 'Succeeded'
}

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  gatewayRequest?: InputMaybe<JsonNullableFilter>;
  gatewayResponse?: InputMaybe<JsonNullableFilter>;
  gatewayTransactionId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  processor?: InputMaybe<EnumPaymentProcessorFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  referenceType?: InputMaybe<StringNullableFilter>;
  requeryResponse?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  topup?: InputMaybe<TopupRelationFilter>;
  topupId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PlaceOrderInput = {
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelledBy?: InputMaybe<OrderActor>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerNotes?: InputMaybe<Scalars['String']>;
  destination: WaypointInput;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  midStops?: InputMaybe<Array<WaypointInput>>;
  orderNumber?: InputMaybe<Scalars['String']>;
  origin: WaypointInput;
  paymentMethod?: InputMaybe<PaymentMethod>;
  scheduledPickupAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<OrderType>;
  vehicleId: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  _count?: Maybe<TieredPriceCount>;
  basePrice: Scalars['Decimal'];
  baseVolume: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Status;
  tiers?: Maybe<Array<PriceTier>>;
  updatedAt: Scalars['DateTime'];
  usedBy: Array<ServiceRate>;
};


export type PriceUsedByArgs = {
  cursor?: InputMaybe<ServiceRateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceRateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceRateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceRateWhereInput>;
};

export type PriceModifier = {
  __typename?: 'PriceModifier';
  days?: Maybe<Array<DayOfWeek>>;
  fromDate?: Maybe<Scalars['DateTime']>;
  timeSlotPrices: Array<TimeSlotPrice>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type PriceModifierInput = {
  days?: InputMaybe<Array<DayOfWeek>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  timeSlotPrices: Array<TimeSlotPriceInput>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type PriceTier = {
  __typename?: 'PriceTier';
  from: Scalars['Float'];
  price: Scalars['Decimal'];
  to?: Maybe<Scalars['Float']>;
};

export type PriceTierInput = {
  from: Scalars['Float'];
  price: Scalars['Decimal'];
  to?: InputMaybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<Array<Account>>;
  activeOrders: PaginatedOrder;
  adminPermission?: Maybe<AdminPermission>;
  adminPermissions: PaginatedAdminPermission;
  adminRole?: Maybe<AdminRole>;
  adminRoles: PaginatedAdminRole;
  adminUser?: Maybe<AdminUser>;
  adminUsers: PaginatedAdminUser;
  aggregateCancellationReason: AggregateCancellationReason;
  aggregateCountry: AggregateCountry;
  aggregateState: AggregateState;
  aggregateVehicleMake: AggregateVehicleMake;
  appRemoteConfig: AppRemoteConfig;
  availableServiceProviders: PaginatedServiceProvider;
  cancellationReason?: Maybe<CancellationReason>;
  cancellationReasons: Array<CancellationReason>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  customer?: Maybe<Customer>;
  customers: PaginatedCustomer;
  dashboardReport: DashboardReport;
  discountCoupon?: Maybe<DiscountCoupon>;
  discountCoupons: PaginatedDiscountCoupon;
  estimateOrder: EstimatedOrder;
  findFirstCancellationReason?: Maybe<CancellationReason>;
  findFirstCancellationReasonOrThrow?: Maybe<CancellationReason>;
  findFirstCountry?: Maybe<Country>;
  findFirstCountryOrThrow?: Maybe<Country>;
  findFirstState?: Maybe<State>;
  findFirstStateOrThrow?: Maybe<State>;
  findFirstVehicleMake?: Maybe<VehicleMake>;
  findFirstVehicleMakeOrThrow?: Maybe<VehicleMake>;
  getCancellationReason?: Maybe<CancellationReason>;
  getCountry?: Maybe<Country>;
  getState?: Maybe<State>;
  getVehicleMake?: Maybe<VehicleMake>;
  groupByCancellationReason: Array<CancellationReasonGroupBy>;
  groupByCountry: Array<CountryGroupBy>;
  groupByState: Array<StateGroupBy>;
  groupByVehicleMake: Array<VehicleMakeGroupBy>;
  health: Scalars['String'];
  me: AuthUserUnion;
  myCustomerVehicle?: Maybe<CustomerVehicle>;
  myCustomerVehicles: PaginatedCustomerVehicle;
  myEmergencyContact?: Maybe<CustomerEmergencyContact>;
  myEmergencyContacts: PaginatedCustomerEmergencyContact;
  myWallet?: Maybe<Wallet>;
  order?: Maybe<Order>;
  orderEvent?: Maybe<OrderEvent>;
  orderEvents: PaginatedOrderEvent;
  orderTransitionStatuses?: Maybe<Array<OrderTransitionStatus>>;
  orders: PaginatedOrder;
  pastOrders: PaginatedOrder;
  payment?: Maybe<Payment>;
  payments: PaginatedPayment;
  review?: Maybe<Review>;
  reviews: PaginatedReview;
  searchAdminUsers: PaginatedAdminUser;
  searchCustomers: PaginatedCustomer;
  searchServiceProviders: PaginatedServiceProvider;
  service?: Maybe<Service>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviders: PaginatedServiceProvider;
  serviceRate?: Maybe<ServiceRate>;
  services: PaginatedService;
  state?: Maybe<State>;
  states: Array<State>;
  systemSetting?: Maybe<SystemSetting>;
  systemSettings: PaginatedSystemSetting;
  tieredPrice?: Maybe<TieredPrice>;
  tieredPrices: PaginatedTieredPrice;
  topup?: Maybe<Topup>;
  transaction?: Maybe<Transaction>;
  transactions: PaginatedTransaction;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleMakes: Array<VehicleMake>;
  verifySocialToken: IdTokenPayload;
  wallet?: Maybe<Wallet>;
  walletBalanceAdjustment?: Maybe<WalletBalanceAdjustment>;
  walletBalanceAdjustments: PaginatedWalletBalanceAdjustment;
  wallets: PaginatedWallet;
  zone?: Maybe<Zone>;
  zones: PaginatedZone;
};


export type QueryActiveOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminPermissionArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminPermissionsArgs = {
  orderBy?: InputMaybe<Array<AdminPermissionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminPermissionWhereInput>;
};


export type QueryAdminRoleArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminRolesArgs = {
  orderBy?: InputMaybe<Array<AdminRoleOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminRoleWhereInput>;
};


export type QueryAdminUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminUsersArgs = {
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryAggregateCancellationReasonArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryAggregateCountryArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryAggregateStateArgs = {
  cursor?: InputMaybe<StateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};


export type QueryAggregateVehicleMakeArgs = {
  cursor?: InputMaybe<VehicleMakeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VehicleMakeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type QueryAppRemoteConfigArgs = {
  appType: AppType;
};


export type QueryAvailableServiceProvidersArgs = {
  orderId: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type QueryCancellationReasonsArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryCountriesArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomersArgs = {
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryDashboardReportArgs = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
  timezone?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<ReportUnit>;
};


export type QueryDiscountCouponArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDiscountCouponsArgs = {
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryEstimateOrderArgs = {
  data: CalculateOrderInput;
};


export type QueryFindFirstCancellationReasonArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryFindFirstCancellationReasonOrThrowArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryFindFirstCountryArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryFindFirstCountryOrThrowArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryFindFirstStateArgs = {
  cursor?: InputMaybe<StateWhereUniqueInput>;
  distinct?: InputMaybe<Array<StateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};


export type QueryFindFirstStateOrThrowArgs = {
  cursor?: InputMaybe<StateWhereUniqueInput>;
  distinct?: InputMaybe<Array<StateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};


export type QueryFindFirstVehicleMakeArgs = {
  cursor?: InputMaybe<VehicleMakeWhereUniqueInput>;
  distinct?: InputMaybe<Array<VehicleMakeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VehicleMakeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type QueryFindFirstVehicleMakeOrThrowArgs = {
  cursor?: InputMaybe<VehicleMakeWhereUniqueInput>;
  distinct?: InputMaybe<Array<VehicleMakeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VehicleMakeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type QueryGetCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type QueryGetCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryGetStateArgs = {
  where: StateWhereUniqueInput;
};


export type QueryGetVehicleMakeArgs = {
  where: VehicleMakeWhereUniqueInput;
};


export type QueryGroupByCancellationReasonArgs = {
  by: Array<CancellationReasonScalarFieldEnum>;
  having?: InputMaybe<CancellationReasonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryGroupByCountryArgs = {
  by: Array<CountryScalarFieldEnum>;
  having?: InputMaybe<CountryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CountryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryGroupByStateArgs = {
  by: Array<StateScalarFieldEnum>;
  having?: InputMaybe<StateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};


export type QueryGroupByVehicleMakeArgs = {
  by: Array<VehicleMakeScalarFieldEnum>;
  having?: InputMaybe<VehicleMakeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VehicleMakeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type QueryMyCustomerVehicleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMyCustomerVehiclesArgs = {
  orderBy?: InputMaybe<Array<CustomerVehicleOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerVehicleWhereInput>;
};


export type QueryMyEmergencyContactArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMyEmergencyContactsArgs = {
  orderBy?: InputMaybe<Array<CustomerEmergencyContactOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerEmergencyContactWhereInput>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderEventArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderEventsArgs = {
  orderBy?: InputMaybe<Array<OrderEventOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderEventWhereInput>;
};


export type QueryOrderTransitionStatusesArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPastOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPaymentsArgs = {
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryReviewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReviewsArgs = {
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type QuerySearchAdminUsersArgs = {
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchCustomersArgs = {
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchServiceProvidersArgs = {
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryServiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceProviderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceProvidersArgs = {
  orderBy?: InputMaybe<Array<ServiceProviderOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ServiceProviderWhereInput>;
};


export type QueryServicesArgs = {
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QueryStateArgs = {
  where: StateWhereUniqueInput;
};


export type QueryStatesArgs = {
  cursor?: InputMaybe<StateWhereUniqueInput>;
  distinct?: InputMaybe<Array<StateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateWhereInput>;
};


export type QuerySystemSettingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySystemSettingsArgs = {
  orderBy?: InputMaybe<Array<SystemSettingOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemSettingWhereInput>;
};


export type QueryTieredPriceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTieredPricesArgs = {
  orderBy?: InputMaybe<Array<TieredPriceOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TieredPriceWhereInput>;
};


export type QueryTopupArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryVehicleMakeArgs = {
  where: VehicleMakeWhereUniqueInput;
};


export type QueryVehicleMakesArgs = {
  cursor?: InputMaybe<VehicleMakeWhereUniqueInput>;
  distinct?: InputMaybe<Array<VehicleMakeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VehicleMakeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VehicleMakeWhereInput>;
};


export type QueryVerifySocialTokenArgs = {
  provider: Scalars['String'];
  token: Scalars['String'];
};


export type QueryWalletArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWalletBalanceAdjustmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWalletBalanceAdjustmentsArgs = {
  orderBy?: InputMaybe<Array<WalletBalanceAdjustmentOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
};


export type QueryWalletsArgs = {
  orderBy?: InputMaybe<Array<WalletOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletWhereInput>;
};


export type QueryZoneArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryZonesArgs = {
  orderBy?: InputMaybe<Array<ZoneOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RedirectRequest = {
  __typename?: 'RedirectRequest';
  body?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<HttpHeader>>;
  params?: Maybe<Array<HttpRequestParameter>>;
  /** GET or POST */
  redirectMethod: Scalars['String'];
  redirectUrl: Scalars['String'];
  returnUrl: Scalars['String'];
};

export enum ReportUnit {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
  Week = 'Week'
}

export type RequestOtpInput = {
  email?: InputMaybe<Scalars['String']>;
  isResend?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  purpose: OtpPurpose;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['String'];
  rating: Scalars['Int'];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  type: ReviewType;
  updatedAt: Scalars['DateTime'];
};

export type ReviewOrderByWithRelationInput = {
  comment?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReviewScalarFieldEnum {
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  DeletedAt = 'deletedAt',
  Id = 'id',
  OrderId = 'orderId',
  Rating = 'rating',
  ServiceProviderId = 'serviceProviderId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum ReviewType {
  CustomerToServiceProvider = 'CustomerToServiceProvider',
  ServiceProviderToCustomer = 'ServiceProviderToCustomer'
}

export type ReviewTypeOrderIdCompoundUniqueInput = {
  orderId: Scalars['String'];
  type: ReviewType;
};

export type ReviewUncheckedCreateInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['String'];
  rating: Scalars['Int'];
  type?: InputMaybe<ReviewType>;
};

export type ReviewWhereInput = {
  AND?: InputMaybe<Array<ReviewWhereInput>>;
  NOT?: InputMaybe<Array<ReviewWhereInput>>;
  OR?: InputMaybe<Array<ReviewWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumReviewTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type_orderId?: InputMaybe<ReviewTypeOrderIdCompoundUniqueInput>;
};

export type Service = {
  __typename?: 'Service';
  _count?: Maybe<ServiceCount>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rates: Array<ServiceRate>;
  status: Status;
  updatedAt: Scalars['DateTime'];
};


export type ServiceRatesArgs = {
  cursor?: InputMaybe<ServiceRateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceRateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceRateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceRateWhereInput>;
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  rates: Scalars['Int'];
};

export type ServiceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rates?: InputMaybe<ServiceRateOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  _count?: Maybe<ServiceProviderCount>;
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  drivingLicense?: Maybe<DrivingLicense>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContact>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  identityDocument?: Maybe<IdentityDocument>;
  isOnDuty: Scalars['Boolean'];
  lastName: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mobileNumber: Scalars['String'];
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  profilePicture?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Decimal']>;
  ratingCount: Scalars['Int'];
  reviews: Array<Review>;
  status: ServiceProviderStatus;
  totalEarnings: Money;
  type: ServiceProviderType;
  unavailablePeriods: Array<ServiceProviderUnavailablePeriod>;
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  /** Get wallet of service provider by currency code. If currency code is not provided, default currency will be used */
  wallet?: Maybe<Wallet>;
  wallets: Array<Wallet>;
};


export type ServiceProviderOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type ServiceProviderReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type ServiceProviderUnavailablePeriodsArgs = {
  cursor?: InputMaybe<ServiceProviderUnavailablePeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceProviderUnavailablePeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceProviderUnavailablePeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
};


export type ServiceProviderWalletArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
};


export type ServiceProviderWalletsArgs = {
  cursor?: InputMaybe<WalletWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletWhereInput>;
};

export type ServiceProviderCount = {
  __typename?: 'ServiceProviderCount';
  orders: Scalars['Int'];
  reviews: Scalars['Int'];
  unavailablePeriods: Scalars['Int'];
  wallets: Scalars['Int'];
};

export type ServiceProviderCreateInputV2 = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  drivingLicense?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['JSON']>;
  firstName: Scalars['String'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  isOnDuty?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobileNumber: Scalars['String'];
  password: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  vehicle?: InputMaybe<Scalars['JSON']>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateNestedOneWithoutWalletsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutWalletsInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutWalletsInput>;
};

export type ServiceProviderCreateOrConnectWithoutUserInput = {
  create: ServiceProviderUncheckedCreateWithoutUserInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutWalletsInput = {
  create: ServiceProviderUncheckedCreateWithoutWalletsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type ServiceProviderOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  bankAccount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  drivingLicense?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emergencyContact?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityDocument?: InputMaybe<SortOrder>;
  isOnDuty?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  ordersCount?: InputMaybe<SortOrder>;
  profilePicture?: InputMaybe<SortOrder>;
  ratingCount?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  vehicle?: InputMaybe<SortOrder>;
  wallets?: InputMaybe<WalletOrderByRelationAggregateInput>;
};

export type ServiceProviderRelationFilter = {
  is?: InputMaybe<ServiceProviderWhereInput>;
  isNot?: InputMaybe<ServiceProviderWhereInput>;
};

export enum ServiceProviderStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export enum ServiceProviderType {
  Freelancer = 'Freelancer',
  Permanent = 'Permanent'
}

export type ServiceProviderUnavailablePeriod = {
  __typename?: 'ServiceProviderUnavailablePeriod';
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderId?: Maybe<Scalars['String']>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceProviderUnavailablePeriodOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ServiceProviderUnavailablePeriodScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndsAt = 'endsAt',
  Id = 'id',
  OrderId = 'orderId',
  ServiceProviderId = 'serviceProviderId',
  StartsAt = 'startsAt',
  UpdatedAt = 'updatedAt'
}

export type ServiceProviderUnavailablePeriodWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endsAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderUnavailablePeriodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceProviderUncheckedCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutUserInput>;
};

export type ServiceProviderUncheckedCreateWithoutUserInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  drivingLicense?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['JSON']>;
  firstName: Scalars['String'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  isOnDuty?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  vehicle?: InputMaybe<Scalars['JSON']>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderUncheckedCreateWithoutWalletsInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  drivingLicense?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['JSON']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  isOnDuty?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  vehicle?: InputMaybe<Scalars['JSON']>;
};

export type ServiceProviderUncheckedUpdateInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  drivingLicense?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<Scalars['JSON']>;
  firstName?: InputMaybe<Scalars['String']>;
  identityDocument?: InputMaybe<Scalars['JSON']>;
  isOnDuty?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  vehicle?: InputMaybe<Scalars['JSON']>;
  wallets?: InputMaybe<WalletUncheckedUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderWhereInput>>;
  address?: InputMaybe<JsonNullableFilter>;
  bankAccount?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  drivingLicense?: InputMaybe<JsonNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emergencyContact?: InputMaybe<JsonNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  identityDocument?: InputMaybe<JsonNullableFilter>;
  isOnDuty?: InputMaybe<BoolFilter>;
  lastName?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  ratingCount?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumServiceProviderStatusFilter>;
  type?: InputMaybe<EnumServiceProviderTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  vehicle?: InputMaybe<JsonNullableFilter>;
  wallets?: InputMaybe<WalletListRelationFilter>;
};

export type ServiceProviderWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type ServiceRate = {
  __typename?: 'ServiceRate';
  createdAt: Scalars['DateTime'];
  defaultTieredPrice: TieredPrice;
  defaultTieredPriceId: Scalars['String'];
  defaults?: Maybe<Array<PriceModifier>>;
  id: Scalars['ID'];
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type ServiceRateCreateManyDefaultTieredPriceInput = {
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateCreateManyDefaultTieredPriceInputEnvelope = {
  data: Array<ServiceRateCreateManyDefaultTieredPriceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceRateCreateManyServiceInput = {
  defaultTieredPriceId: Scalars['String'];
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateCreateManyServiceInputEnvelope = {
  data: Array<ServiceRateCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceRateCreateOrConnectWithoutDefaultTieredPriceInput = {
  create: ServiceRateUncheckedCreateWithoutDefaultTieredPriceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateCreateOrConnectWithoutServiceInput = {
  create: ServiceRateUncheckedCreateWithoutServiceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateCreateWithoutDefaultTieredPriceInput = {
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateCreateWithoutServiceInput = {
  defaultTieredPrice: TieredPriceCreateNestedOneWithoutUsedByInput;
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateListRelationFilter = {
  every?: InputMaybe<ServiceRateWhereInput>;
  none?: InputMaybe<ServiceRateWhereInput>;
  some?: InputMaybe<ServiceRateWhereInput>;
};

export type ServiceRateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ServiceRateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  defaultTieredPrice?: InputMaybe<TieredPriceOrderByWithRelationInput>;
  defaultTieredPriceId?: InputMaybe<SortOrder>;
  defaults?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  overrides?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ServiceRateScalarFieldEnum {
  CreatedAt = 'createdAt',
  DefaultTieredPriceId = 'defaultTieredPriceId',
  Defaults = 'defaults',
  Id = 'id',
  Overrides = 'overrides',
  ServiceId = 'serviceId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ServiceRateScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceRateScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceRateScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceRateScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultTieredPriceId?: InputMaybe<StringFilter>;
  defaults?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  overrides?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceRateUncheckedCreateNestedManyWithoutDefaultTieredPriceInput = {
  connect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceRateCreateOrConnectWithoutDefaultTieredPriceInput>>;
  create?: InputMaybe<Array<ServiceRateCreateWithoutDefaultTieredPriceInput>>;
  createMany?: InputMaybe<ServiceRateCreateManyDefaultTieredPriceInputEnvelope>;
};

export type ServiceRateUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceRateCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<ServiceRateCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceRateCreateManyServiceInputEnvelope>;
};

export type ServiceRateUncheckedCreateWithoutDefaultTieredPriceInput = {
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUncheckedCreateWithoutServiceInput = {
  defaultTieredPriceId: Scalars['String'];
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUncheckedUpdateManyWithoutDefaultTieredPriceNestedInput = {
  connect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceRateCreateOrConnectWithoutDefaultTieredPriceInput>>;
  create?: InputMaybe<Array<ServiceRateCreateWithoutDefaultTieredPriceInput>>;
  createMany?: InputMaybe<ServiceRateCreateManyDefaultTieredPriceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceRateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceRateUpdateWithWhereUniqueWithoutDefaultTieredPriceInput>>;
  updateMany?: InputMaybe<Array<ServiceRateUpdateManyWithWhereWithoutDefaultTieredPriceInput>>;
  upsert?: InputMaybe<Array<ServiceRateUpsertWithWhereUniqueWithoutDefaultTieredPriceInput>>;
};

export type ServiceRateUncheckedUpdateManyWithoutRatesInput = {
  defaultTieredPriceId?: InputMaybe<Scalars['String']>;
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceRateCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<ServiceRateCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceRateCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceRateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceRateWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceRateUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<ServiceRateUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<ServiceRateUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceRateUncheckedUpdateManyWithoutUsedByInput = {
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUncheckedUpdateWithoutDefaultTieredPriceInput = {
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUncheckedUpdateWithoutServiceInput = {
  defaultTieredPriceId?: InputMaybe<Scalars['String']>;
  defaults?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUpdateInputV2 = {
  defaultTieredPriceId?: InputMaybe<Scalars['String']>;
  defaults?: InputMaybe<Array<PriceModifierInput>>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
};

export type ServiceRateUpdateManyWithWhereWithoutDefaultTieredPriceInput = {
  data: ServiceRateUncheckedUpdateManyWithoutUsedByInput;
  where: ServiceRateScalarWhereInput;
};

export type ServiceRateUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceRateUncheckedUpdateManyWithoutRatesInput;
  where: ServiceRateScalarWhereInput;
};

export type ServiceRateUpdateWithWhereUniqueWithoutDefaultTieredPriceInput = {
  data: ServiceRateUncheckedUpdateWithoutDefaultTieredPriceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceRateUncheckedUpdateWithoutServiceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateUpsertWithWhereUniqueWithoutDefaultTieredPriceInput = {
  create: ServiceRateUncheckedCreateWithoutDefaultTieredPriceInput;
  update: ServiceRateUncheckedUpdateWithoutDefaultTieredPriceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceRateUncheckedCreateWithoutServiceInput;
  update: ServiceRateUncheckedUpdateWithoutServiceInput;
  where: ServiceRateWhereUniqueInput;
};

export type ServiceRateWhereInput = {
  AND?: InputMaybe<Array<ServiceRateWhereInput>>;
  NOT?: InputMaybe<Array<ServiceRateWhereInput>>;
  OR?: InputMaybe<Array<ServiceRateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultTieredPrice?: InputMaybe<TieredPriceRelationFilter>;
  defaultTieredPriceId?: InputMaybe<StringFilter>;
  defaults?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  overrides?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceRateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceUncheckedCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  rates?: InputMaybe<ServiceRateUncheckedCreateNestedManyWithoutServiceInput>;
  status?: InputMaybe<Status>;
};

export type ServiceUncheckedUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  rates?: InputMaybe<ServiceRateUncheckedUpdateManyWithoutServiceNestedInput>;
  status?: InputMaybe<Status>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  rates?: InputMaybe<ServiceRateListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export type SocialLoginInput = {
  email?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  token: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type State = {
  __typename?: 'State';
  code: Scalars['String'];
  country: Country;
  countryId: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Status;
};

export type StateAvgAggregate = {
  __typename?: 'StateAvgAggregate';
  countryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type StateAvgOrderByAggregateInput = {
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StateCountAggregate = {
  __typename?: 'StateCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  countryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StateCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type StateCreateManyCountryInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type StateCreateManyCountryInputEnvelope = {
  data: Array<StateCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StateCreateManyInput = {
  code: Scalars['String'];
  countryId: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type StateCreateOrConnectWithoutCountryInput = {
  create: StateUncheckedCreateWithoutCountryInput;
  where: StateWhereUniqueInput;
};

export type StateCreateWithoutCountryInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type StateGroupBy = {
  __typename?: 'StateGroupBy';
  _avg?: Maybe<StateAvgAggregate>;
  _count?: Maybe<StateCountAggregate>;
  _max?: Maybe<StateMaxAggregate>;
  _min?: Maybe<StateMinAggregate>;
  _sum?: Maybe<StateSumAggregate>;
  code: Scalars['String'];
  countryId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type StateListRelationFilter = {
  every?: InputMaybe<StateWhereInput>;
  none?: InputMaybe<StateWhereInput>;
  some?: InputMaybe<StateWhereInput>;
};

export type StateMaxAggregate = {
  __typename?: 'StateMaxAggregate';
  code?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StateMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type StateMinAggregate = {
  __typename?: 'StateMinAggregate';
  code?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StateMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type StateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StateOrderByWithAggregationInput = {
  _avg?: InputMaybe<StateAvgOrderByAggregateInput>;
  _count?: InputMaybe<StateCountOrderByAggregateInput>;
  _max?: InputMaybe<StateMaxOrderByAggregateInput>;
  _min?: InputMaybe<StateMinOrderByAggregateInput>;
  _sum?: InputMaybe<StateSumOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type StateOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  country?: InputMaybe<CountryOrderByWithRelationInput>;
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export enum StateScalarFieldEnum {
  Code = 'code',
  CountryId = 'countryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type StateScalarWhereInput = {
  AND?: InputMaybe<Array<StateScalarWhereInput>>;
  NOT?: InputMaybe<Array<StateScalarWhereInput>>;
  OR?: InputMaybe<Array<StateScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  countryId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type StateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StateScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  countryId?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type StateSumAggregate = {
  __typename?: 'StateSumAggregate';
  countryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type StateSumOrderByAggregateInput = {
  countryId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type StateUncheckedCreateInput = {
  code: Scalars['String'];
  countryId: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type StateUncheckedCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<StateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StateCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<StateCreateWithoutCountryInput>>;
  createMany?: InputMaybe<StateCreateManyCountryInputEnvelope>;
};

export type StateUncheckedCreateWithoutCountryInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type StateUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type StateUncheckedUpdateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type StateUncheckedUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<StateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StateCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<StateCreateWithoutCountryInput>>;
  createMany?: InputMaybe<StateCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<StateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StateWhereUniqueInput>>;
  set?: InputMaybe<Array<StateWhereUniqueInput>>;
  update?: InputMaybe<Array<StateUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<StateUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<StateUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type StateUncheckedUpdateManyWithoutStatesInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type StateUncheckedUpdateWithoutCountryInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type StateUpdateManyWithWhereWithoutCountryInput = {
  data: StateUncheckedUpdateManyWithoutStatesInput;
  where: StateScalarWhereInput;
};

export type StateUpdateWithWhereUniqueWithoutCountryInput = {
  data: StateUncheckedUpdateWithoutCountryInput;
  where: StateWhereUniqueInput;
};

export type StateUpsertWithWhereUniqueWithoutCountryInput = {
  create: StateUncheckedCreateWithoutCountryInput;
  update: StateUncheckedUpdateWithoutCountryInput;
  where: StateWhereUniqueInput;
};

export type StateWhereInput = {
  AND?: InputMaybe<Array<StateWhereInput>>;
  NOT?: InputMaybe<Array<StateWhereInput>>;
  OR?: InputMaybe<Array<StateWhereInput>>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type StateWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type StatusResponse = {
  __typename?: 'StatusResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderPlaced?: Maybe<Order>;
};

export type SystemSetting = {
  __typename?: 'SystemSetting';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  group: SystemSettingGroup;
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['JSON'];
};

export enum SystemSettingGroup {
  AppSettings = 'AppSettings'
}

export type SystemSettingOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type SystemSettingUncheckedUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<SystemSettingGroup>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type SystemSettingWhereInput = {
  AND?: InputMaybe<Array<SystemSettingWhereInput>>;
  NOT?: InputMaybe<Array<SystemSettingWhereInput>>;
  OR?: InputMaybe<Array<SystemSettingWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  group?: InputMaybe<EnumSystemSettingGroupFilter>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type SystemSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type TieredPrice = {
  __typename?: 'TieredPrice';
  _count?: Maybe<TieredPriceCount>;
  basePrice: Scalars['Decimal'];
  baseVolume: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Status;
  tiers?: Maybe<Array<PriceTier>>;
  updatedAt: Scalars['DateTime'];
  usedBy: Array<ServiceRate>;
};


export type TieredPriceUsedByArgs = {
  cursor?: InputMaybe<ServiceRateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceRateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceRateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceRateWhereInput>;
};

export type TieredPriceCount = {
  __typename?: 'TieredPriceCount';
  usedBy: Scalars['Int'];
};

export type TieredPriceCreateInputV2 = {
  basePrice: Scalars['Decimal'];
  baseVolume?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  tiers?: InputMaybe<Array<PriceTierInput>>;
  usedBy?: InputMaybe<ServiceRateUncheckedCreateNestedManyWithoutDefaultTieredPriceInput>;
};

export type TieredPriceCreateNestedOneWithoutUsedByInput = {
  connect?: InputMaybe<TieredPriceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TieredPriceCreateOrConnectWithoutUsedByInput>;
  create?: InputMaybe<TieredPriceUncheckedCreateWithoutUsedByInput>;
};

export type TieredPriceCreateOrConnectWithoutUsedByInput = {
  create: TieredPriceUncheckedCreateWithoutUsedByInput;
  where: TieredPriceWhereUniqueInput;
};

export type TieredPriceOrderByWithRelationInput = {
  basePrice?: InputMaybe<SortOrder>;
  baseVolume?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tiers?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  usedBy?: InputMaybe<ServiceRateOrderByRelationAggregateInput>;
};

export type TieredPriceRelationFilter = {
  is?: InputMaybe<TieredPriceWhereInput>;
  isNot?: InputMaybe<TieredPriceWhereInput>;
};

export type TieredPriceUncheckedCreateWithoutUsedByInput = {
  basePrice: Scalars['Decimal'];
  baseVolume?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  tiers?: InputMaybe<Scalars['JSON']>;
};

export type TieredPriceUpdateInputV2 = {
  basePrice?: InputMaybe<Scalars['Decimal']>;
  baseVolume?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  tiers?: InputMaybe<Array<PriceTierInput>>;
  usedBy?: InputMaybe<ServiceRateUncheckedUpdateManyWithoutDefaultTieredPriceNestedInput>;
};

export type TieredPriceWhereInput = {
  AND?: InputMaybe<Array<TieredPriceWhereInput>>;
  NOT?: InputMaybe<Array<TieredPriceWhereInput>>;
  OR?: InputMaybe<Array<TieredPriceWhereInput>>;
  basePrice?: InputMaybe<DecimalFilter>;
  baseVolume?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  tiers?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedBy?: InputMaybe<ServiceRateListRelationFilter>;
};

export type TieredPriceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TimeSlotPrice = {
  __typename?: 'TimeSlotPrice';
  from: Scalars['String'];
  price: Price;
  priceId: Scalars['String'];
  to: Scalars['String'];
};

export type TimeSlotPriceInput = {
  from: Scalars['String'];
  priceId: Scalars['String'];
  to: Scalars['String'];
};

export type TopUpWalletInput = {
  amount: Scalars['Decimal'];
  method?: InputMaybe<TopupMethod>;
};

export type TopUpWalletRequest = {
  __typename?: 'TopUpWalletRequest';
  amount: Scalars['Decimal'];
  id: Scalars['ID'];
  paymentId: Scalars['ID'];
  redirectRequest?: Maybe<RedirectRequest>;
  status: TopupStatus;
};

export type Topup = {
  __typename?: 'Topup';
  _count?: Maybe<TopupCount>;
  amount: Money;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  events: Array<TopupEvent>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  payments: Array<Payment>;
  status: TopupStatus;
  topupMethod: TopupMethod;
  updatedAt: Scalars['DateTime'];
  wallet: Wallet;
  walletId: Scalars['String'];
};


export type TopupEventsArgs = {
  cursor?: InputMaybe<TopupEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TopupEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TopupEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopupEventWhereInput>;
};


export type TopupPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};

export type TopupCount = {
  __typename?: 'TopupCount';
  events: Scalars['Int'];
  payments: Scalars['Int'];
};

export type TopupEvent = {
  __typename?: 'TopupEvent';
  createdAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['JSON'];
  topup: Topup;
  topupId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TopupEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  eventType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  topup?: InputMaybe<TopupOrderByWithRelationInput>;
  topupId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TopupEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  Id = 'id',
  Payload = 'payload',
  TopupId = 'topupId',
  UpdatedAt = 'updatedAt'
}

export type TopupEventWhereInput = {
  AND?: InputMaybe<Array<TopupEventWhereInput>>;
  NOT?: InputMaybe<Array<TopupEventWhereInput>>;
  OR?: InputMaybe<Array<TopupEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  topup?: InputMaybe<TopupRelationFilter>;
  topupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TopupEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TopupListRelationFilter = {
  every?: InputMaybe<TopupWhereInput>;
  none?: InputMaybe<TopupWhereInput>;
  some?: InputMaybe<TopupWhereInput>;
};

export enum TopupMethod {
  PaymentGateway = 'PaymentGateway'
}

export type TopupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TopupOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  failureCode?: InputMaybe<SortOrder>;
  failureMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  topupMethod?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wallet?: InputMaybe<WalletOrderByWithRelationInput>;
  walletId?: InputMaybe<SortOrder>;
};

export type TopupRelationFilter = {
  is?: InputMaybe<TopupWhereInput>;
  isNot?: InputMaybe<TopupWhereInput>;
};

export enum TopupScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  Id = 'id',
  Metadata = 'metadata',
  Status = 'status',
  TopupMethod = 'topupMethod',
  UpdatedAt = 'updatedAt',
  WalletId = 'walletId'
}

export enum TopupStatus {
  Expired = 'Expired',
  Failed = 'Failed',
  Pending = 'Pending',
  Reversed = 'Reversed',
  Succeeded = 'Succeeded'
}

export type TopupWhereInput = {
  AND?: InputMaybe<Array<TopupWhereInput>>;
  NOT?: InputMaybe<Array<TopupWhereInput>>;
  OR?: InputMaybe<Array<TopupWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  status?: InputMaybe<EnumTopupStatusFilter>;
  topupMethod?: InputMaybe<EnumTopupMethodFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wallet?: InputMaybe<WalletRelationFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export type TopupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Money;
  balanceType: BalanceType;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endingBalance?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  metafields?: Maybe<Array<Metafield>>;
  notes?: Maybe<Scalars['JSON']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  type: TransactionType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  walletId?: Maybe<Scalars['String']>;
};

export type TransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  balanceType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  endingBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  referenceType?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
};

export enum TransactionStatus {
  Succeeded = 'Succeeded'
}

export enum TransactionType {
  Adjustment = 'Adjustment',
  Payment = 'Payment',
  Refund = 'Refund',
  Topup = 'Topup',
  Withdrawal = 'Withdrawal'
}

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  balanceType?: InputMaybe<EnumBalanceTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  endingBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  notes?: InputMaybe<JsonNullableFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  referenceType?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTransactionStatusFilter>;
  type?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  walletId?: InputMaybe<StringNullableFilter>;
};

export type Urls = {
  __typename?: 'Urls';
  aboutUs: Scalars['String'];
  privacyPolicy: Scalars['String'];
  termsOfUse: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  accounts: Array<Account>;
  admin?: Maybe<AdminUser>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  secondaryUsername?: Maybe<Scalars['String']>;
  serviceProvider?: Maybe<ServiceProvider>;
  status: UserStatus;
  type: UserType;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};


export type UserAccountsArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  accounts: Scalars['Int'];
};

export type UserCreateNestedOneWithoutAdminInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdminInput>;
  create?: InputMaybe<UserUncheckedCreateWithoutAdminInput>;
};

export type UserCreateOrConnectWithoutAdminInput = {
  create: UserUncheckedCreateWithoutAdminInput;
  where: UserWhereUniqueInput;
};

export type UserOrderByWithRelationInput = {
  accounts?: InputMaybe<AccountOrderByRelationAggregateInput>;
  admin?: InputMaybe<AdminUserOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  secondaryUsername?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum UserType {
  Admin = 'Admin',
  Customer = 'Customer',
  ServiceProvider = 'ServiceProvider'
}

export type UserTypeUsernameSecondaryUsernameCompoundUniqueInput = {
  secondaryUsername: Scalars['String'];
  type: UserType;
  username: Scalars['String'];
};

export type UserUncheckedCreateWithoutAdminInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutUserInput>;
  customer?: InputMaybe<CustomerUncheckedCreateNestedOneWithoutUserInput>;
  id?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  secondaryUsername?: InputMaybe<Scalars['String']>;
  serviceProvider?: InputMaybe<ServiceProviderUncheckedCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserStatus>;
  type: UserType;
  username: Scalars['String'];
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  admin?: InputMaybe<AdminUserRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringNullableFilter>;
  secondaryUsername?: InputMaybe<StringNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type_username_secondaryUsername?: InputMaybe<UserTypeUsernameSecondaryUsernameCompoundUniqueInput>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  attachments?: Maybe<Array<GenericFile>>;
  licensePlate?: Maybe<Scalars['String']>;
  vehicleColor?: Maybe<Scalars['String']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleRegistrationDate?: Maybe<Scalars['DateTime']>;
};

export type VehicleMake = {
  __typename?: 'VehicleMake';
  isPopular: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['ID'];
  status: Status;
};

export type VehicleMakeCountAggregate = {
  __typename?: 'VehicleMakeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  isPopular: Scalars['Int'];
  logo: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VehicleMakeCountOrderByAggregateInput = {
  isPopular?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VehicleMakeCreateManyInput = {
  isPopular?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['ID'];
  status?: InputMaybe<Status>;
};

export type VehicleMakeGroupBy = {
  __typename?: 'VehicleMakeGroupBy';
  _count?: Maybe<VehicleMakeCountAggregate>;
  _max?: Maybe<VehicleMakeMaxAggregate>;
  _min?: Maybe<VehicleMakeMinAggregate>;
  createdAt: Scalars['DateTime'];
  isPopular: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type VehicleMakeMaxAggregate = {
  __typename?: 'VehicleMakeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  isPopular?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleMakeMaxOrderByAggregateInput = {
  isPopular?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VehicleMakeMinAggregate = {
  __typename?: 'VehicleMakeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  isPopular?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleMakeMinOrderByAggregateInput = {
  isPopular?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VehicleMakeOrderByWithAggregationInput = {
  _count?: InputMaybe<VehicleMakeCountOrderByAggregateInput>;
  _max?: InputMaybe<VehicleMakeMaxOrderByAggregateInput>;
  _min?: InputMaybe<VehicleMakeMinOrderByAggregateInput>;
  isPopular?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VehicleMakeOrderByWithRelationInput = {
  isPopular?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export enum VehicleMakeScalarFieldEnum {
  CreatedAt = 'createdAt',
  IsPopular = 'isPopular',
  Logo = 'logo',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type VehicleMakeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VehicleMakeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VehicleMakeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VehicleMakeScalarWhereWithAggregatesInput>>;
  isPopular?: InputMaybe<BoolWithAggregatesFilter>;
  logo?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type VehicleMakeUncheckedCreateInput = {
  isPopular?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['ID'];
  status?: InputMaybe<Status>;
};

export type VehicleMakeUncheckedUpdateInput = {
  isPopular?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
};

export type VehicleMakeUncheckedUpdateManyInput = {
  isPopular?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
};

export type VehicleMakeWhereInput = {
  AND?: InputMaybe<Array<VehicleMakeWhereInput>>;
  NOT?: InputMaybe<Array<VehicleMakeWhereInput>>;
  OR?: InputMaybe<Array<VehicleMakeWhereInput>>;
  isPopular?: InputMaybe<BoolFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
};

export type VehicleMakeWhereUniqueInput = {
  name?: InputMaybe<Scalars['ID']>;
};

export type VehicleSnapshot = {
  __typename?: 'VehicleSnapshot';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  licensePlate: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};

export type VerifyOtpInput = {
  challengeId: Scalars['String'];
  code: Scalars['String'];
};

export type Wallet = {
  __typename?: 'Wallet';
  _count?: Maybe<WalletCount>;
  availableBalance: Money;
  balance: Money;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars['String']>;
  topups: Array<Topup>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  walletBalanceAdjustments: Array<WalletBalanceAdjustment>;
};


export type WalletTopupsArgs = {
  cursor?: InputMaybe<TopupWhereUniqueInput>;
  distinct?: InputMaybe<Array<TopupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TopupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopupWhereInput>;
};


export type WalletWalletBalanceAdjustmentsArgs = {
  cursor?: InputMaybe<WalletBalanceAdjustmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletBalanceAdjustmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletBalanceAdjustmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
};

export type WalletBalanceAdjustment = {
  __typename?: 'WalletBalanceAdjustment';
  _count?: Maybe<WalletBalanceAdjustmentCount>;
  adjustmentType: AdjustmentType;
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  createdBy: AdminUser;
  createdById: Scalars['String'];
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  remarks?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  wallet: Wallet;
  walletBalanceAdjustmentEvents: Array<WalletBalanceAdjustmentEvent>;
  walletId: Scalars['String'];
};


export type WalletBalanceAdjustmentWalletBalanceAdjustmentEventsArgs = {
  cursor?: InputMaybe<WalletBalanceAdjustmentEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletBalanceAdjustmentEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletBalanceAdjustmentEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletBalanceAdjustmentEventWhereInput>;
};

export type WalletBalanceAdjustmentCount = {
  __typename?: 'WalletBalanceAdjustmentCount';
  walletBalanceAdjustmentEvents: Scalars['Int'];
};

export type WalletBalanceAdjustmentCreateManyCreatedByInput = {
  adjustmentType: AdjustmentType;
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  walletId: Scalars['String'];
};

export type WalletBalanceAdjustmentCreateManyCreatedByInputEnvelope = {
  data: Array<WalletBalanceAdjustmentCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WalletBalanceAdjustmentCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentCreateManyCreatedByInputEnvelope>;
};

export type WalletBalanceAdjustmentCreateOrConnectWithoutCreatedByInput = {
  create: WalletBalanceAdjustmentUncheckedCreateWithoutCreatedByInput;
  where: WalletBalanceAdjustmentWhereUniqueInput;
};

export type WalletBalanceAdjustmentCreateWithoutCreatedByInput = {
  adjustmentType: AdjustmentType;
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  wallet: WalletCreateNestedOneWithoutWalletBalanceAdjustmentsInput;
  walletBalanceAdjustmentEvents?: InputMaybe<WalletBalanceAdjustmentEventCreateNestedManyWithoutWalletBalanceAdjustmentInput>;
};

export type WalletBalanceAdjustmentEvent = {
  __typename?: 'WalletBalanceAdjustmentEvent';
  createdAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
  walletBalanceAdjustment: WalletBalanceAdjustment;
  walletBalanceAdjustmentId: Scalars['String'];
};

export type WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInput = {
  eventType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  payload: Scalars['JSON'];
};

export type WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInputEnvelope = {
  data: Array<WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WalletBalanceAdjustmentEventCreateNestedManyWithoutWalletBalanceAdjustmentInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateOrConnectWithoutWalletBalanceAdjustmentInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateWithoutWalletBalanceAdjustmentInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInputEnvelope>;
};

export type WalletBalanceAdjustmentEventCreateOrConnectWithoutWalletBalanceAdjustmentInput = {
  create: WalletBalanceAdjustmentEventUncheckedCreateWithoutWalletBalanceAdjustmentInput;
  where: WalletBalanceAdjustmentEventWhereUniqueInput;
};

export type WalletBalanceAdjustmentEventCreateWithoutWalletBalanceAdjustmentInput = {
  eventType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  payload: Scalars['JSON'];
};

export type WalletBalanceAdjustmentEventListRelationFilter = {
  every?: InputMaybe<WalletBalanceAdjustmentEventWhereInput>;
  none?: InputMaybe<WalletBalanceAdjustmentEventWhereInput>;
  some?: InputMaybe<WalletBalanceAdjustmentEventWhereInput>;
};

export type WalletBalanceAdjustmentEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WalletBalanceAdjustmentEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  eventType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  walletBalanceAdjustment?: InputMaybe<WalletBalanceAdjustmentOrderByWithRelationInput>;
  walletBalanceAdjustmentId?: InputMaybe<SortOrder>;
};

export enum WalletBalanceAdjustmentEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  Id = 'id',
  Payload = 'payload',
  UpdatedAt = 'updatedAt',
  WalletBalanceAdjustmentId = 'walletBalanceAdjustmentId'
}

export type WalletBalanceAdjustmentEventScalarWhereInput = {
  AND?: InputMaybe<Array<WalletBalanceAdjustmentEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<WalletBalanceAdjustmentEventScalarWhereInput>>;
  OR?: InputMaybe<Array<WalletBalanceAdjustmentEventScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  walletBalanceAdjustmentId?: InputMaybe<StringFilter>;
};

export type WalletBalanceAdjustmentEventUncheckedCreateNestedManyWithoutWalletBalanceAdjustmentInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateOrConnectWithoutWalletBalanceAdjustmentInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateWithoutWalletBalanceAdjustmentInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInputEnvelope>;
};

export type WalletBalanceAdjustmentEventUncheckedCreateWithoutWalletBalanceAdjustmentInput = {
  eventType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  payload: Scalars['JSON'];
};

export type WalletBalanceAdjustmentEventUncheckedUpdateManyWithoutWalletBalanceAdjustmentEventsInput = {
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  payload?: InputMaybe<Scalars['JSON']>;
};

export type WalletBalanceAdjustmentEventUncheckedUpdateManyWithoutWalletBalanceAdjustmentNestedInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateOrConnectWithoutWalletBalanceAdjustmentInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentEventCreateWithoutWalletBalanceAdjustmentInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentEventCreateManyWalletBalanceAdjustmentInputEnvelope>;
  delete?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WalletBalanceAdjustmentEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  set?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereUniqueInput>>;
  update?: InputMaybe<Array<WalletBalanceAdjustmentEventUpdateWithWhereUniqueWithoutWalletBalanceAdjustmentInput>>;
  updateMany?: InputMaybe<Array<WalletBalanceAdjustmentEventUpdateManyWithWhereWithoutWalletBalanceAdjustmentInput>>;
  upsert?: InputMaybe<Array<WalletBalanceAdjustmentEventUpsertWithWhereUniqueWithoutWalletBalanceAdjustmentInput>>;
};

export type WalletBalanceAdjustmentEventUncheckedUpdateWithoutWalletBalanceAdjustmentInput = {
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  payload?: InputMaybe<Scalars['JSON']>;
};

export type WalletBalanceAdjustmentEventUpdateManyWithWhereWithoutWalletBalanceAdjustmentInput = {
  data: WalletBalanceAdjustmentEventUncheckedUpdateManyWithoutWalletBalanceAdjustmentEventsInput;
  where: WalletBalanceAdjustmentEventScalarWhereInput;
};

export type WalletBalanceAdjustmentEventUpdateWithWhereUniqueWithoutWalletBalanceAdjustmentInput = {
  data: WalletBalanceAdjustmentEventUncheckedUpdateWithoutWalletBalanceAdjustmentInput;
  where: WalletBalanceAdjustmentEventWhereUniqueInput;
};

export type WalletBalanceAdjustmentEventUpsertWithWhereUniqueWithoutWalletBalanceAdjustmentInput = {
  create: WalletBalanceAdjustmentEventUncheckedCreateWithoutWalletBalanceAdjustmentInput;
  update: WalletBalanceAdjustmentEventUncheckedUpdateWithoutWalletBalanceAdjustmentInput;
  where: WalletBalanceAdjustmentEventWhereUniqueInput;
};

export type WalletBalanceAdjustmentEventWhereInput = {
  AND?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereInput>>;
  NOT?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereInput>>;
  OR?: InputMaybe<Array<WalletBalanceAdjustmentEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  walletBalanceAdjustment?: InputMaybe<WalletBalanceAdjustmentRelationFilter>;
  walletBalanceAdjustmentId?: InputMaybe<StringFilter>;
};

export type WalletBalanceAdjustmentEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type WalletBalanceAdjustmentListRelationFilter = {
  every?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
  none?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
  some?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
};

export type WalletBalanceAdjustmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WalletBalanceAdjustmentOrderByWithRelationInput = {
  adjustmentType?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<AdminUserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wallet?: InputMaybe<WalletOrderByWithRelationInput>;
  walletBalanceAdjustmentEvents?: InputMaybe<WalletBalanceAdjustmentEventOrderByRelationAggregateInput>;
  walletId?: InputMaybe<SortOrder>;
};

export type WalletBalanceAdjustmentRelationFilter = {
  is?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
  isNot?: InputMaybe<WalletBalanceAdjustmentWhereInput>;
};

export enum WalletBalanceAdjustmentScalarFieldEnum {
  AdjustmentType = 'adjustmentType',
  Amount = 'amount',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  CurrencyCode = 'currencyCode',
  Id = 'id',
  Remarks = 'remarks',
  UpdatedAt = 'updatedAt',
  WalletId = 'walletId'
}

export type WalletBalanceAdjustmentScalarWhereInput = {
  AND?: InputMaybe<Array<WalletBalanceAdjustmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<WalletBalanceAdjustmentScalarWhereInput>>;
  OR?: InputMaybe<Array<WalletBalanceAdjustmentScalarWhereInput>>;
  adjustmentType?: InputMaybe<EnumAdjustmentTypeFilter>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export type WalletBalanceAdjustmentUncheckedCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentCreateManyCreatedByInputEnvelope>;
};

export type WalletBalanceAdjustmentUncheckedCreateWithoutCreatedByInput = {
  adjustmentType: AdjustmentType;
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  walletBalanceAdjustmentEvents?: InputMaybe<WalletBalanceAdjustmentEventUncheckedCreateNestedManyWithoutWalletBalanceAdjustmentInput>;
  walletId: Scalars['String'];
};

export type WalletBalanceAdjustmentUncheckedUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletBalanceAdjustmentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<WalletBalanceAdjustmentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<WalletBalanceAdjustmentCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WalletBalanceAdjustmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  set?: InputMaybe<Array<WalletBalanceAdjustmentWhereUniqueInput>>;
  update?: InputMaybe<Array<WalletBalanceAdjustmentUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<WalletBalanceAdjustmentUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<WalletBalanceAdjustmentUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type WalletBalanceAdjustmentUncheckedUpdateManyWithoutWalletBalanceAdjustmentsInput = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  amount?: InputMaybe<Scalars['Decimal']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  walletId?: InputMaybe<Scalars['String']>;
};

export type WalletBalanceAdjustmentUncheckedUpdateWithoutCreatedByInput = {
  adjustmentType?: InputMaybe<AdjustmentType>;
  amount?: InputMaybe<Scalars['Decimal']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  walletBalanceAdjustmentEvents?: InputMaybe<WalletBalanceAdjustmentEventUncheckedUpdateManyWithoutWalletBalanceAdjustmentNestedInput>;
  walletId?: InputMaybe<Scalars['String']>;
};

export type WalletBalanceAdjustmentUpdateManyWithWhereWithoutCreatedByInput = {
  data: WalletBalanceAdjustmentUncheckedUpdateManyWithoutWalletBalanceAdjustmentsInput;
  where: WalletBalanceAdjustmentScalarWhereInput;
};

export type WalletBalanceAdjustmentUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: WalletBalanceAdjustmentUncheckedUpdateWithoutCreatedByInput;
  where: WalletBalanceAdjustmentWhereUniqueInput;
};

export type WalletBalanceAdjustmentUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: WalletBalanceAdjustmentUncheckedCreateWithoutCreatedByInput;
  update: WalletBalanceAdjustmentUncheckedUpdateWithoutCreatedByInput;
  where: WalletBalanceAdjustmentWhereUniqueInput;
};

export type WalletBalanceAdjustmentWhereInput = {
  AND?: InputMaybe<Array<WalletBalanceAdjustmentWhereInput>>;
  NOT?: InputMaybe<Array<WalletBalanceAdjustmentWhereInput>>;
  OR?: InputMaybe<Array<WalletBalanceAdjustmentWhereInput>>;
  adjustmentType?: InputMaybe<EnumAdjustmentTypeFilter>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<AdminUserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wallet?: InputMaybe<WalletRelationFilter>;
  walletBalanceAdjustmentEvents?: InputMaybe<WalletBalanceAdjustmentEventListRelationFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export type WalletBalanceAdjustmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type WalletCount = {
  __typename?: 'WalletCount';
  topups: Scalars['Int'];
  walletBalanceAdjustments: Scalars['Int'];
};

export type WalletCreateManyCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type WalletCreateManyCustomerInputEnvelope = {
  data: Array<WalletCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WalletCreateManyServiceProviderInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletCreateManyServiceProviderInputEnvelope = {
  data: Array<WalletCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WalletCreateNestedOneWithoutWalletBalanceAdjustmentsInput = {
  connect?: InputMaybe<WalletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WalletCreateOrConnectWithoutWalletBalanceAdjustmentsInput>;
  create?: InputMaybe<WalletUncheckedCreateWithoutWalletBalanceAdjustmentsInput>;
};

export type WalletCreateOrConnectWithoutCustomerInput = {
  create: WalletUncheckedCreateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletCreateOrConnectWithoutServiceProviderInput = {
  create: WalletUncheckedCreateWithoutServiceProviderInput;
  where: WalletWhereUniqueInput;
};

export type WalletCreateOrConnectWithoutWalletBalanceAdjustmentsInput = {
  create: WalletUncheckedCreateWithoutWalletBalanceAdjustmentsInput;
  where: WalletWhereUniqueInput;
};

export type WalletCreateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutWalletsInput>;
  userId: Scalars['String'];
};

export type WalletCreateWithoutServiceProviderInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  customer?: InputMaybe<CustomerCreateNestedOneWithoutWalletsInput>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletListRelationFilter = {
  every?: InputMaybe<WalletWhereInput>;
  none?: InputMaybe<WalletWhereInput>;
  some?: InputMaybe<WalletWhereInput>;
};

export type WalletOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WalletOrderByWithRelationInput = {
  availableBalance?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  holdBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  topups?: InputMaybe<TopupOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentOrderByRelationAggregateInput>;
};

export type WalletRelationFilter = {
  is?: InputMaybe<WalletWhereInput>;
  isNot?: InputMaybe<WalletWhereInput>;
};

export enum WalletScalarFieldEnum {
  AvailableBalance = 'availableBalance',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  CustomerId = 'customerId',
  HoldBalance = 'holdBalance',
  Id = 'id',
  ServiceProviderId = 'serviceProviderId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type WalletScalarWhereInput = {
  AND?: InputMaybe<Array<WalletScalarWhereInput>>;
  NOT?: InputMaybe<Array<WalletScalarWhereInput>>;
  OR?: InputMaybe<Array<WalletScalarWhereInput>>;
  availableBalance?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  holdBalance?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type WalletUncheckedCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<WalletCreateManyCustomerInputEnvelope>;
};

export type WalletUncheckedCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutServiceProviderInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<WalletCreateManyServiceProviderInputEnvelope>;
};

export type WalletUncheckedCreateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type WalletUncheckedCreateWithoutServiceProviderInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletUncheckedCreateWithoutWalletBalanceAdjustmentsInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type WalletUncheckedUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<WalletCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<WalletWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WalletScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  set?: InputMaybe<Array<WalletWhereUniqueInput>>;
  update?: InputMaybe<Array<WalletUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<WalletUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<WalletUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type WalletUncheckedUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutServiceProviderInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<WalletCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<WalletWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WalletScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  set?: InputMaybe<Array<WalletWhereUniqueInput>>;
  update?: InputMaybe<Array<WalletUpdateWithWhereUniqueWithoutServiceProviderInput>>;
  updateMany?: InputMaybe<Array<WalletUpdateManyWithWhereWithoutServiceProviderInput>>;
  upsert?: InputMaybe<Array<WalletUpsertWithWhereUniqueWithoutServiceProviderInput>>;
};

export type WalletUncheckedUpdateManyWithoutWalletsInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalletUncheckedUpdateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalletUncheckedUpdateWithoutServiceProviderInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalletUpdateManyWithWhereWithoutCustomerInput = {
  data: WalletUncheckedUpdateManyWithoutWalletsInput;
  where: WalletScalarWhereInput;
};

export type WalletUpdateManyWithWhereWithoutServiceProviderInput = {
  data: WalletUncheckedUpdateManyWithoutWalletsInput;
  where: WalletScalarWhereInput;
};

export type WalletUpdateWithWhereUniqueWithoutCustomerInput = {
  data: WalletUncheckedUpdateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: WalletUncheckedUpdateWithoutServiceProviderInput;
  where: WalletWhereUniqueInput;
};

export type WalletUpsertWithWhereUniqueWithoutCustomerInput = {
  create: WalletUncheckedCreateWithoutCustomerInput;
  update: WalletUncheckedUpdateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: WalletUncheckedCreateWithoutServiceProviderInput;
  update: WalletUncheckedUpdateWithoutServiceProviderInput;
  where: WalletWhereUniqueInput;
};

export type WalletUserIdCurrencyCodeCompoundUniqueInput = {
  currencyCode: Scalars['String'];
  userId: Scalars['String'];
};

export type WalletWhereInput = {
  AND?: InputMaybe<Array<WalletWhereInput>>;
  NOT?: InputMaybe<Array<WalletWhereInput>>;
  OR?: InputMaybe<Array<WalletWhereInput>>;
  availableBalance?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  holdBalance?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringNullableFilter>;
  topups?: InputMaybe<TopupListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  walletBalanceAdjustments?: InputMaybe<WalletBalanceAdjustmentListRelationFilter>;
};

export type WalletWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  userId_currencyCode?: InputMaybe<WalletUserIdCurrencyCodeCompoundUniqueInput>;
};

export type Waypoint = {
  __typename?: 'Waypoint';
  address: Scalars['String'];
  coordinates: Coordinates;
  formattedAddress: Scalars['String'];
  name: Scalars['String'];
  placeId?: Maybe<Scalars['String']>;
};

export type WaypointInput = {
  address: Scalars['String'];
  coordinates: CoordinatesInput;
  name: Scalars['String'];
  placeId?: InputMaybe<Scalars['String']>;
};

export type Zone = {
  __typename?: 'Zone';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  type: ZoneType;
  updatedAt: Scalars['DateTime'];
};

export type ZoneOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ZoneType {
  City = 'City',
  Point = 'Point',
  Polygon = 'Polygon',
  PostCodeRange = 'PostCodeRange',
  State = 'State'
}

export type ZoneUncheckedCreateInput = {
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  type: ZoneType;
};

export type ZoneUncheckedUpdateInput = {
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ZoneType>;
};

export type ZoneWhereInput = {
  AND?: InputMaybe<Array<ZoneWhereInput>>;
  NOT?: InputMaybe<Array<ZoneWhereInput>>;
  OR?: InputMaybe<Array<ZoneWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumZoneTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ZoneWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateAdminRoleMutationVariables = Exact<{
  data: AdminRoleCreateInputV2;
}>;


export type CreateAdminRoleMutation = { __typename?: 'Mutation', createOneAdminRole: { __typename?: 'AdminRole', id: string } };

export type CreateAdminUserMutationVariables = Exact<{
  data: AdminUserCreateInputV2;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createOneAdminUser: { __typename?: 'AdminUser', id: string } };

export type CreateDiscountCouponMutationVariables = Exact<{
  data: DiscountCouponUncheckedCreateInput;
}>;


export type CreateDiscountCouponMutation = { __typename?: 'Mutation', createOneDiscountCoupon: { __typename?: 'DiscountCoupon', id: string } };

export type CreateServiceProviderMutationVariables = Exact<{
  data: ServiceProviderCreateInputV2;
}>;


export type CreateServiceProviderMutation = { __typename?: 'Mutation', createOneServiceProvider: { __typename?: 'ServiceProvider', id: string } };

export type CreateTierPriceMutationVariables = Exact<{
  data: TieredPriceCreateInputV2;
}>;


export type CreateTierPriceMutation = { __typename?: 'Mutation', createOneTieredPrice: { __typename?: 'TieredPrice', id: string } };

export type ServiceRateQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceRateQuery = { __typename?: 'Query', serviceRate?: { __typename?: 'ServiceRate', status: Status, updatedAt: any, defaultTieredPriceId: string, defaultTieredPrice: { __typename?: 'TieredPrice', name: string, tiers?: Array<{ __typename?: 'PriceTier', to?: number | null, from: number, price: any }> | null }, defaults?: Array<{ __typename?: 'PriceModifier', days?: Array<DayOfWeek> | null, fromDate?: any | null, toDate?: any | null, timeSlotPrices: Array<{ __typename?: 'TimeSlotPrice', from: string, to: string, priceId: string, price: { __typename?: 'Price', name: string, tiers?: Array<{ __typename?: 'PriceTier', to?: number | null, from: number, price: any }> | null } }> }> | null } | null };

export type UpdateAdminRoleMutationVariables = Exact<{
  data: AdminRoleUpdateInputV2;
  where: AdminRoleWhereUniqueInput;
}>;


export type UpdateAdminRoleMutation = { __typename?: 'Mutation', updateOneAdminRole?: { __typename?: 'AdminRole', id: string } | null };

export type UpdateAdminUserMutationVariables = Exact<{
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
}>;


export type UpdateAdminUserMutation = { __typename?: 'Mutation', updateOneAdminUser?: { __typename?: 'AdminUser', id: string } | null };

export type UpdateDiscountCouponMutationVariables = Exact<{
  data: DiscountCouponUncheckedUpdateInput;
  where: DiscountCouponWhereUniqueInput;
}>;


export type UpdateDiscountCouponMutation = { __typename?: 'Mutation', updateOneDiscountCoupon?: { __typename?: 'DiscountCoupon', id: string } | null };

export type UpdateServiceProviderMutationVariables = Exact<{
  data: ServiceProviderUncheckedUpdateInput;
  where: ServiceProviderWhereUniqueInput;
}>;


export type UpdateServiceProviderMutation = { __typename?: 'Mutation', updateOneServiceProvider?: { __typename?: 'ServiceProvider', id: string } | null };

export type UpdateServiceRateMutationVariables = Exact<{
  data: ServiceRateUpdateInputV2;
}>;


export type UpdateServiceRateMutation = { __typename?: 'Mutation', updateOneServiceRate?: { __typename?: 'ServiceRate', id: string } | null };

export type UpdateTierPriceMutationVariables = Exact<{
  data: TieredPriceUpdateInputV2;
  where: TieredPriceWhereUniqueInput;
}>;


export type UpdateTierPriceMutation = { __typename?: 'Mutation', updateOneTieredPrice?: { __typename?: 'TieredPrice', id: string } | null };


export const CreateAdminRoleDocument = gql`
    mutation createAdminRole($data: AdminRoleCreateInputV2!) {
  createOneAdminRole(data: $data) {
    id
  }
}
    `;
export const CreateAdminUserDocument = gql`
    mutation createAdminUser($data: AdminUserCreateInputV2!) {
  createOneAdminUser(data: $data) {
    id
  }
}
    `;
export const CreateDiscountCouponDocument = gql`
    mutation createDiscountCoupon($data: DiscountCouponUncheckedCreateInput!) {
  createOneDiscountCoupon(data: $data) {
    id
  }
}
    `;
export const CreateServiceProviderDocument = gql`
    mutation createServiceProvider($data: ServiceProviderCreateInputV2!) {
  createOneServiceProvider(data: $data) {
    id
  }
}
    `;
export const CreateTierPriceDocument = gql`
    mutation createTierPrice($data: TieredPriceCreateInputV2!) {
  createOneTieredPrice(data: $data) {
    id
  }
}
    `;
export const ServiceRateDocument = gql`
    query serviceRate {
  serviceRate {
    status
    updatedAt
    defaultTieredPriceId
    defaultTieredPrice {
      name
      tiers {
        to
        from
        price
      }
    }
    defaults {
      days
      fromDate
      toDate
      timeSlotPrices {
        from
        to
        priceId
        price {
          name
          tiers {
            to
            from
            price
          }
        }
      }
    }
  }
}
    `;
export const UpdateAdminRoleDocument = gql`
    mutation updateAdminRole($data: AdminRoleUpdateInputV2!, $where: AdminRoleWhereUniqueInput!) {
  updateOneAdminRole(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateAdminUserDocument = gql`
    mutation updateAdminUser($data: AdminUserUpdateInputV2!, $where: AdminUserWhereUniqueInput!) {
  updateOneAdminUser(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateDiscountCouponDocument = gql`
    mutation updateDiscountCoupon($data: DiscountCouponUncheckedUpdateInput!, $where: DiscountCouponWhereUniqueInput!) {
  updateOneDiscountCoupon(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateServiceProviderDocument = gql`
    mutation updateServiceProvider($data: ServiceProviderUncheckedUpdateInput!, $where: ServiceProviderWhereUniqueInput!) {
  updateOneServiceProvider(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateServiceRateDocument = gql`
    mutation updateServiceRate($data: ServiceRateUpdateInputV2!) {
  updateOneServiceRate(data: $data) {
    id
  }
}
    `;
export const UpdateTierPriceDocument = gql`
    mutation updateTierPrice($data: TieredPriceUpdateInputV2!, $where: TieredPriceWhereUniqueInput!) {
  updateOneTieredPrice(data: $data, where: $where) {
    id
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createAdminRole(variables: CreateAdminRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAdminRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminRoleMutation>(CreateAdminRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAdminRole', 'mutation');
    },
    createAdminUser(variables: CreateAdminUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminUserMutation>(CreateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAdminUser', 'mutation');
    },
    createDiscountCoupon(variables: CreateDiscountCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDiscountCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDiscountCouponMutation>(CreateDiscountCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDiscountCoupon', 'mutation');
    },
    createServiceProvider(variables: CreateServiceProviderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateServiceProviderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateServiceProviderMutation>(CreateServiceProviderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createServiceProvider', 'mutation');
    },
    createTierPrice(variables: CreateTierPriceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTierPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTierPriceMutation>(CreateTierPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createTierPrice', 'mutation');
    },
    serviceRate(variables?: ServiceRateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ServiceRateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ServiceRateQuery>(ServiceRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'serviceRate', 'query');
    },
    updateAdminRole(variables: UpdateAdminRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAdminRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminRoleMutation>(UpdateAdminRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAdminRole', 'mutation');
    },
    updateAdminUser(variables: UpdateAdminUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminUserMutation>(UpdateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAdminUser', 'mutation');
    },
    updateDiscountCoupon(variables: UpdateDiscountCouponMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDiscountCouponMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDiscountCouponMutation>(UpdateDiscountCouponDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDiscountCoupon', 'mutation');
    },
    updateServiceProvider(variables: UpdateServiceProviderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateServiceProviderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateServiceProviderMutation>(UpdateServiceProviderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateServiceProvider', 'mutation');
    },
    updateServiceRate(variables: UpdateServiceRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateServiceRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateServiceRateMutation>(UpdateServiceRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateServiceRate', 'mutation');
    },
    updateTierPrice(variables: UpdateTierPriceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTierPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTierPriceMutation>(UpdateTierPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateTierPrice', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;