import { Tag, type TagProps } from '@scalingworks/react-admin-ui';

import type { OrderStatus } from '~/api';

const colorByStatus: Record<OrderStatus, TagProps['color']> = {
	Allocating: 'amber',
	Ongoing: 'blue',
	Completed: 'green',
	Cancelled: 'red',
	Failed: 'gray',
};

export const OrderStatusTag = ({
	status,
	displayText = status,
}: {
	status: OrderStatus;
	displayText?: string;
}) => <Tag color={colorByStatus[status]}>{displayText}</Tag>;
