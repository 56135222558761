import { assertExists } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { type AdminPermission, type AdminRole, getSdk } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineResourceCheckbox, defineCardSection, defineShowPage } =
	createHelpers<AdminRole>({
		resourceName: resourceNames.adminRole,
	});

const defaultValues = {
	code: '',
	name: '',
	description: '',
	permissions: [] as string[],
};

export const adminRoleResource = createResource({
	name: resourceNames.adminRole,
	label: 'Admin Roles',
	fields: defineFields(['id', 'code', 'name', 'description', 'permissions']),
	defaultValues,
	dataProvider: {
		create: ({ client, variables }) =>
			getSdk(client)
				.createAdminRole({
					data: variables,
				})
				.then((res) => ({
					data: res.createOneAdminRole,
				})),
		update: ({ client, variables, id }) =>
			getSdk(client)
				.updateAdminRole({
					data: variables,
					where: {
						id: id as string,
					},
				})
				.then((res) => {
					assertExists(res.updateOneAdminRole);

					return {
						data: res.updateOneAdminRole,
					};
				}),
	},
	controls: {
		components: {
			description: {
				type: 'text',
				config: {
					label: 'Description',
					required: false,
				},
			},
			permissions: defineResourceCheckbox<AdminPermission>({
				resourceName: 'adminPermission',
				fields: ['code', 'name'],
				optionLabel: 'name',
				optionValue: 'code',
				label: 'Permissions',
			}),
		},
		componentConfigDefaults: {
			required: true,
		},
	},
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	columns: ({ LinkToDetails }) => [
		{
			id: 'name',
			header: 'Name',
			accessorKey: 'name',
			cell: (data) => {
				const name = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={data.row.original.id} className="font-semibold">
						{name}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'description',
			header: 'Description',
			accessorKey: 'description',
		},
	],
	allowEdit: false,
	allowDelete: true,
	show: defineShowPage({
		sections: [
			defineCardSection({
				title: 'General',
				fields: ['name', 'code', 'description', 'permissions'],
				editControls: {
					permissions: defineResourceCheckbox<AdminPermission>({
						resourceName: 'adminPermission',
						fields: ['code', 'name'],
						optionLabel: 'name',
						optionValue: 'code',
						label: 'Permissions',
					}),
				},
			}),
		],
	}),
});
