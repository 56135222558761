import { Dialog, Menu } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { OrderCancelForm } from './order-cancel-form';
import { OrderChangeStatusForm } from './order-change-status-form';
import { OrderSetEstimatedTimeForm } from './order-set-estimated-time-form';

export const OrderActionsMenu = (props: {
	orderId: string;
	onSuccess: () => void;
	button: React.ReactNode;
	disabled?: boolean;
}) => {
	const [showCancelDialog, setShowCancelDialog] = React.useState(false);
	const [showChangeStatusDialog, setShowChangeStatusDialog] = React.useState(false);
	const [showChangeEstimatedTimeDialog, setShowChangeEstimatedTimeDialog] = React.useState(false);

	return (
		<>
			<Menu.Root>
				<Menu.Trigger disabled={props.disabled} asChild>{props.button}</Menu.Trigger>
				<Menu.Portal>
					<Menu.Content align="end">
						<Menu.Item onSelect={() => setShowCancelDialog(true)}>Cancel</Menu.Item>
						<Menu.Item onSelect={() => setShowChangeStatusDialog(true)}>Change Status</Menu.Item>
						<Menu.Item onSelect={() => setShowChangeEstimatedTimeDialog(true)}>
							Set Estimated Arrival Time
						</Menu.Item>
					</Menu.Content>
				</Menu.Portal>
			</Menu.Root>
			<Dialog.Root open={showCancelDialog} onOpenChange={setShowCancelDialog}>
				<Dialog.Portal>
					<Dialog.Overlay />
					<Dialog.Content>
						<Dialog.Title>Cancel Order</Dialog.Title>
						<OrderCancelForm
							orderId={props.orderId}
							onSuccess={() => {
								props.onSuccess();
								setShowCancelDialog(false);
							}}
						/>
						<Dialog.CloseButton />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
			<Dialog.Root open={showChangeStatusDialog} onOpenChange={setShowChangeStatusDialog}>
				<Dialog.Portal>
					<Dialog.Overlay />
					<Dialog.Content>
						<Dialog.Title>Change Status</Dialog.Title>
						<OrderChangeStatusForm
							orderId={props.orderId}
							onSuccess={() => {
								props.onSuccess();
								setShowChangeStatusDialog(false);
							}}
						/>
						<Dialog.CloseButton />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
			<Dialog.Root
				open={showChangeEstimatedTimeDialog}
				onOpenChange={setShowChangeEstimatedTimeDialog}
			>
				<Dialog.Portal>
					<Dialog.Overlay />
					<Dialog.Content>
						<Dialog.Title>Set Estimated Arrival Time</Dialog.Title>
						<OrderSetEstimatedTimeForm
							orderId={props.orderId}
							onSuccess={() => {
								props.onSuccess();
								setShowChangeEstimatedTimeDialog(false);
							}}
						/>
						<Dialog.CloseButton />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
		</>
	);
};
