import { assertExists } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, sentenceCase } from '@scalingworks/refine-react-admin';

import { CouponStatus, type DiscountCoupon, DiscountType, getSdk } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<DiscountCoupon>({
	resourceName: resourceNames.coupons,
});

const [formattedScheduledTime] = defineFields([
	{
		operation: 'formattedScheduledTime',
		variables: {
			timezone: {
				value: Intl.DateTimeFormat().resolvedOptions().timeZone,
				required: true,
				type: 'String',
			},
		},
		fields: [],
	},
]);

export const discountCouponResource = createResource({
	name: resourceNames.coupons,
	label: 'Promo Codes',
	fields: defineFields([
		'id',
		'name',
		'code',
		formattedScheduledTime,
		'formattedDiscountValue',
		'totalUses',
		'status',
		'startsAt',
		'endsAt',
		'discountType',
		'discountValue',
		'maxDiscountAmount',
	]),
	dataProvider: {
		create: ({ client, variables }) =>
			getSdk(client)
				.createDiscountCoupon({
					data: variables,
				})
				.then((res) => ({
					data: res.createOneDiscountCoupon,
				})),
		update: ({ client, variables, id }) =>
			getSdk(client)
				.updateDiscountCoupon({
					data: variables,
					where: {
						id: id as string,
					},
				})
				.then((res) => {
					assertExists(
						res.updateOneDiscountCoupon,
						'value returned by updateDiscountCoupon is undefined'
					);

					return {
						data: res.updateOneDiscountCoupon,
					};
				}),
	},
	defaultValues: {
		name: '',
		code: '',
		startsAt: '',
		endsAt: '',
		discountType: DiscountType.FixedAmount,
		discountValue: '',
		maxDiscountAmount: '',
	},
	controls: {
		components: {
			startsAt: {
				type: 'date',
				config: {
					label: 'Starts at',
				},
			},
			endsAt: {
				type: 'date',
				config: {
					label: 'Ends at',
				},
			},
			discountType: {
				type: 'select',
				config: {
					label: 'Discount type',
					options: Object.entries(DiscountType).map(([label, value]) => ({
						label: sentenceCase(label),
						value,
					})),
				},
			},
		},
		componentConfigDefaults: {
			required: true,
		},
	},
	columns: ({ LinkToDetails }) => [
		{
			id: 'name',
			header: 'Name',
			accessorKey: 'name',
			cell: (data) => {
				const name = data.cell.getValue<string>();

				return (
					<LinkToDetails resourceId={data.row.original.id} className="font-semibold">
						{name}
					</LinkToDetails>
				);
			},
		},
		{
			id: 'code',
			header: 'Code',
			accessorKey: 'code',
		},
		{
			id: 'formattedScheduledTime',
			header: 'Duration',
			accessorKey: 'formattedScheduledTime',
		},
		{
			id: 'totalUses',
			header: 'Usages',
			accessorKey: 'totalUses',
		},
		{
			id: 'status',
			header: 'Status',
			accessorKey: 'status',
		},
	],
	filterControls: {
		name: {
			type: 'text',
			config: {
				label: 'Name',
			},
			operator: 'contains',
		},
		code: {
			type: 'text',
			config: {
				label: 'Code',
			},
			operator: 'contains',
		},
		status: {
			type: 'select',
			config: {
				label: 'Status',
				options: Object.entries(CouponStatus).map(([label, value]) => ({
					label: sentenceCase(label),
					value,
				})),
			},
			operator: 'eq',
		},
	},
	defaultSorter: [{ field: 'createdAt', order: 'desc' }],
	show: defineShowPage({
		title: (data) => data.name,
		sections: [
			defineCardSection({
				title: 'Details',
				fields: [
					'name',
					'code',
					'status',
					formattedScheduledTime,
					'discountType',
					'discountValue',
					'maxDiscountAmount',
				],
				displays: {
					formattedScheduledTime: {
						label: 'Duration',
						render: (data) => data.formattedScheduledTime,
					},
				},
			}),
		],
	}),
});
